import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast/headless";
import { backend_url } from "../../App";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { RxCrossCircled } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const Countries = [
  {
    value: "India",
    label: "India",
  },
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Russia",
    label: "Russia",
  },
  {
    value: "South Korea",
    label: "South Korea",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
];

export const CreateCommoditi = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState();
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [createdOn, setCreatedOn] = useState("2024-05-03T00:00:00");
  const [description, setDescription] = useState("");
  const [quality, setQuality] = useState("");
  const [packaging, setPackaging] = useState("");
  const [specification, setSpecification] = useState();
  const [image, setImage] = useState("");
  const [importing, setImporting] = useState([]);
  const [exporting, setExporting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [commodityGallery, setCommodityGallery] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [commodityId, setCommodityId] = useState("");
  const [uploadedGallerys, setUploadedGallerys] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (id && id.length > 1) {
      getCommoditie();
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/category/get-all-category`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleImageFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles([...imageFiles, ...files]);
  };

  const removeImageFile = (index) => {
    const newFiles = [...imageFiles];
    newFiles.splice(index, 1);
    setImageFiles(newFiles);
  };

  const handleAddFAQ = () => {
    if (question && answer) {
      const newFAQ = { question, answer };
      setFaqs([...faqs, newFAQ]);
      setQuestion("");
      setAnswer("");
    }
  };

  const handleImportChange = async (selected, selection) => {
    const { action } = selection;
    if (action === "clear") {
    } else if (action === "select-option") {
    } else if (action === "remove-value") {
    }

    setImporting(selected);
  };
  const handleExportChange = async (selected, selection) => {
    const { action } = selection;
    if (action === "clear") {
    } else if (action === "select-option") {
    } else if (action === "remove-value") {
    }

    setExporting(selected);
  };

  const getCommoditie = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/commodity/get-commodity?commodity_id=${id}`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setImage(response.data.commodity_image);
        setCategory(response.data.commodity_category);
        setName(response.data.commodity_name);
        setData(response.data.created_on);
        setDescription(response.data.description);
        setQuality(response.data.quality);
        setPackaging(response.data.packaging_details);
        setSpecification(response.data.specification.specification);
        setImporting(response.data.countries_importing);
        setExporting(response.data.countries_exporting);
        setFaqs(response.data.FAQ);
        setUploadedGallerys(response.data.commodity_gallery);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  function handleChange(e) {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImage(URL.createObjectURL(selectedFile));
    }
  }

  // Function to format the date to YYYY-MM-DD
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Use this useEffect to initialize the createdOn state with formatted date if needed
  useEffect(() => {
    // Replace with your initial date string
    setCreatedOn(formatDate(data));
  }, [data]);

  const uploadImage = (commodityId) => {
    // console.log("uploading image");
    // console.log(commodityId);
    setLoading(true);

    if (!commodityId || !file) {
      const missingFields = [];
      if (!commodityId) missingFields.push("commodityId");
      if (!file) missingFields.push("imageFile");

      toast.error(
        `Please fill in the following fields: ${missingFields.join(", ")}`
      );
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    const options = {
      method: "POST",
      url: `${backend_url}/commodity/upload-image`,
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      params: {
        commodity_id: commodityId,
      },
      data: formData,
    };

    axios
      .request(options)
      .then((response) => {
        toast.success("Image uploaded successfully!");
        // console.log(response.data);
        setCommodityGallery(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to upload image");
        setLoading(false);
      });
  };

  const handleCreate = () => {
    setLoading(true);

    const options = {
      method: "POST",
      url: `${backend_url}/commodity/create-commodity`,
      headers: { "Content-Type": "application/json" },
      data: {
        commodity_category: category,
        commodity_name: name,
        commodity_image: "",
        created_on: createdOn,
        description: description,
        specification: { specification },
        quality: quality,
        packaging_details: packaging,
        FAQ: faqs,
        countries_importing: importing,
        countries_exporting: exporting,
        // commodity_gallery: [null],
        past_shipment: {},
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        if (typeof file === "object") {
          uploadImage(response.data.commodity_id);
        } else {
          setLoading(false);
        }
        setCommodityId(response.data.commodity_id);
        toast.success(response.data.message);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const handleUpdate = () => {
    setLoading(true);

    const options = {
      method: "PATCH",
      url: `${backend_url}/commodity/edit-commodity/${id}`,
      headers: { "Content-Type": "application/json" },
      data: {
        commodity_category: category,
        commodity_name: name,
        commodity_image: "",
        created_on: createdOn,
        description: description,
        specification: { specification },
        quality: quality,
        packaging_details: packaging,
        FAQ: faqs,
        countries_importing: importing,
        countries_exporting: exporting,
        // commodity_gallery: [null],
        past_shipment: {},
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        if (typeof file === "object") {
          uploadImage(id);
        } else {
          setCommodityGallery(true);
          setLoading(false);
        }
        setCommodityId(id);
        toast.success(response.data.message);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleAddGallery = () => {
    setLoading(true);
    // console.log("handle add gallery api was running");
    const form = new FormData();

    imageFiles.forEach((file) => {
      form.append("images", file);
    });

    const options = {
      method: "PUT",
      url: `${backend_url}/commodity/upload-images-commodity-gallery`,
      params: {
        commodity_id: commodityId,
      },
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: form,
    };

    axios
      .request(options)
      .then((response) => {
        // console.log(response.data);
        toast.success(response.data.message);
        setImageFiles([]);
        setLoading(false);
        navigate(`/admin/commodities`);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to upload images");
        setLoading(false);
      });
  };

  const handleSkip = () => {
    setCommodityGallery(false);
    navigate(`/admin/commodities`);
  };

  return (
    <>
      {commodityGallery ? (
        <div className="fixed inset-0 flex justify-center z-50">
          <div className="absolute inset-0 bg-black/60 opacity-75"></div>
          <div className="bg-white rounded-md z-50 self-start min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-20 sm:mr-20 px-4 relative">
            <h1 className="text-2xl font-semibold mb-5">Commodity Gallery</h1>
            <div className="flex flex-col">
              <label htmlFor="img" className="font-bold">
                Images:
              </label>
              <input
                type="file"
                id="img"
                className="border p-2"
                onChange={handleImageFileChange}
                multiple
              />
            </div>

            <div className="flex flex-col">
              <label className="font-bold text-lg">Selected Images:</label>
              <div className="flex w-full gap-3 flex-wrap">
                {imageFiles.map((file, index) => (
                  <div key={index} className="relative">
                    <div className="w-[70px] h-[70px]">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index}`}
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          background: "cover",
                        }}
                      />
                    </div>
                    <RxCrossCircled
                      className="absolute top-0 right-0 cursor-pointer"
                      onClick={() => removeImageFile(index)}
                    />
                  </div>
                ))}
              </div>
            </div>

            {uploadedGallerys.length > 0 && (
              <div className="">
                <h1 className="font-bold text-lg">
                  Uploaded Gallery images :{" "}
                </h1>
                <div className="flex gap-2">
                  {uploadedGallerys.map((data, index) => (
                    <div className="" key={index}>
                      <img
                        src={data}
                        alt=""
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          background: "cover",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex justify-end">
              {loading ? (
                <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white">
                  Loading...
                </button>
              ) : (
                // editId ? (
                //   <button
                //     className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white"
                //     onClick={editGalleryInfo}
                //   >
                //     Save changes
                //   </button>
                // ) :
                <div className="flex justify-between  pl-8  w-full bottom-5 absolute">
                  <button
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white"
                    onClick={handleSkip}
                  >
                    Skip
                  </button>
                  <button
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white"
                    onClick={handleAddGallery}
                  >
                    Add Gallery
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-[80vh] w-full border border-spacing-1 flex flex-col justify-center items-center">
          <h1 className="text-2xl font-semibold mt-2">Create Commodities</h1>
          <div className="flex justify-center flex-col gap-3 w-[600px] md:w-[750px] lg:w-[900px] mt-1 py-5">
            <div className="flex flex-col items-center sm:flex-row  w-full gap-5">
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="fname" className="font-semibold">
                  Name:
                </label>
                <input
                  type="text"
                  value={name}
                  className="border p-2 rounded-md"
                  onChange={(e) =>
                    setName(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                />
              </div>
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="company_name" className="font-semibold">
                  Category:
                </label>
                {/* <input
                  type="text"
                  value={category}
                  onChange={(e) =>
                    setCategory(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                  className="border p-2 rounded-md "
                /> */}
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  name=""
                  value={category}
                  id=""
                  className=" border-2 border-black rounded-lg h-10 px-2 w-full "
                >
                  <option value="" className="">
                    Select Category
                  </option>
                  {categories.map((data) => (
                    <option value={data.category_name} className="">
                      {data.category_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col items-center sm:flex-row  w-full gap-5">
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="mobile" className="font-semibold">
                  Specification:
                </label>
                <input
                  type="city"
                  value={specification}
                  onChange={(e) =>
                    setSpecification(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                  className="border p-2 rounded-md "
                />
              </div>
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="mobile" className="font-semibold">
                  Quality:
                </label>
                <input
                  type="text"
                  value={quality}
                  onChange={(e) => setQuality(e.target.value)}
                  className="border p-2 rounded-md capitalize"
                />
              </div>
            </div>
            <div className="flex flex-col items-center sm:flex-row  w-full gap-5">
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="mobile" className="font-semibold">
                  Importing Countries:
                </label>
                <CreatableSelect
                  id="selectCause"
                  value={importing}
                  instanceId="selectCause"
                  isMulti
                  name="colors"
                  className="basic-multi-select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary: "#773fc6",
                    },
                  })}
                  classNamePrefix="select"
                  options={Countries}
                  onChange={handleImportChange}
                />
              </div>
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label className="font-semibold">Exporting Countries:</label>
                <CreatableSelect
                  id="selectCause"
                  value={exporting}
                  instanceId="selectCause"
                  isMulti
                  name="colors"
                  className="basic-multi-select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary: "#773fc6",
                    },
                  })}
                  classNamePrefix="select"
                  options={Countries}
                  onChange={handleExportChange}
                />
              </div>
            </div>

            <div className="flex flex-col items-center sm:flex-row  w-full gap-5">
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="address" className="font-semibold">
                  Packaging Detail:
                </label>

                <input
                  type="text"
                  name=""
                  value={packaging}
                  onChange={(e) => setPackaging(e.target.value)}
                  className="border p-2 rounded-md capitalize"
                />
              </div>
              <div className="flex flex-col gap-2 w-[85%] sm:w-1/2">
                <label for="Date" className="font-semibold">
                  Created On:
                </label>
                <input
                  type="date"
                  value={createdOn}
                  onChange={(e) => setCreatedOn(e.target.value)}
                  className="border p-2 rounded-md "
                />
              </div>
            </div>

            <div>
              <h1 className="font-semibold mb-2">FAQs : </h1>
              <div className="flex flex-col">
                <div className="mb-2">
                  <input
                    type="text"
                    placeholder="Enter your question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    className="border p-2 rounded-md capitalize w-full"
                  />
                </div>
                <div className="mb-2">
                  <input
                    type="text"
                    placeholder="Enter your answer"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    className="border p-2 rounded-md capitalize w-full"
                  />
                </div>
                <button
                  className="py-3 px-5 w-44  bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  onClick={handleAddFAQ}
                >
                  Add FAQ
                </button>
              </div>
              <ul>
                {faqs.map((faq, index) => (
                  <li key={index}>
                    <strong>Q: {faq.question}</strong>
                    <p>A: {faq.answer}</p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col gap-2 w-[85%] sm:w-full">
              <label for="details" className="font-semibold">
                Description:
              </label>
              <textarea
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border p-2 rounded-md capitalize"
              />
            </div>
            <h2 className="font-semibold">Add Image:</h2>
            <input type="file" onChange={handleChange} />

            {file || image ? (
              <img
                src={image ? image : URL.createObjectURL(file)}
                alt="Image"
                style={{
                  width: "70px",
                  height: "70px",
                  objectFit: "cover",
                  background: "cover",
                  marginTop: "5px",
                }}
              />
            ) : null}

            <div className="flex justify-end">
              {loading ? (
                <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                  Loading
                </button>
              ) : id && id.length > 1 ? (
                <button
                  onClick={handleUpdate}
                  className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                >
                  Edit Catalogue
                </button>
              ) : (
                <button
                  onClick={handleCreate}
                  className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                >
                  Add Catalogue
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
