import React, { useEffect, useState } from "react";

import axios from "axios";
import Catmodal from "../components/Catmodal";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const Category = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/category/get-all-category`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const edit = (id) => {
    setShowModal(true);
    setCategoryId(id);

    // console.log(id, "EDIT KA ID");
  };
  const handleModal = () => {
    getData();
    setShowModal(false);
  };

  const handleDeleteCancel = () => {
    setDeleteId(null);
  };

  const Delete = (categoryId) => {
    setDeleteId(categoryId);
  };

  const handleDeleteConfirm = (id) => {
    // console.log(id);
    const options = {
      method: "DELETE",
      url: `${backend_url}/category/delete-category/${id}`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setDeleteId(null);
        getData();
        toast.success(response.data.message);
      })
      .catch(function (error) {
        console.error(error);
        toast.error(error);
      });
  };

  return (
    <>
      <div className="pt-3 pb-5">
        <div className="w-full xl:w-[1100px] m-auto px-6 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold ">Category</h1>
            <button
              className="border-2 border-black px-5 text-lg  h-9 font-semibold"
              onClick={() => setShowModal(true)}
            >
              Add Category
            </button>
          </div>
          <div className="grid  lg:grid-cols-2 gap-x-2 gap-y-4">
            {categories.map((item) => (
              <div className="border-2 p-4" key={item.id}>
                <div className="relative flex flex-col  sm:flex-row gap-4 items-center">
                  <img
                    src={item.category_image_url}
                    alt=""
                    className="  w-[330px] sm:w-[150px]  h-[180px] sm:h-[150px]"
                  />
                  <div className="">
                    <h1 className=" font-semibold">
                      Name - {item.category_name}
                    </h1>
                    {/* <h1 className="font-semibold">
                      Rank - {item.category_rank}
                    </h1> */}
                    {/* <h1 className="font-semibold">
                      URL-{item.category_image_url}
                    </h1> */}
                    <p className="font-semibold">
                      Created On-{item.created_on}
                    </p>
                  </div>
                  <div className="flex gap-4 sm:gap-2 sm:absolute right-0 top-0">
                    <button
                      className="border-2 border-black px-10  sm:px-5 py-1 sm:py-0   font-semibold"
                      onClick={() => edit(item.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="border-2 border-black px-10  sm:px-5 py-1 sm:py-0   font-semibold"
                      onClick={() => Delete(item.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {deleteId !== null && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">
              Are you sure you want to delete this category?
            </h2>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                onClick={() => handleDeleteConfirm(deleteId)}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="">
          <Catmodal
            handleModal={handleModal}
            setShowModal={setShowModal}
            getData={getData}
            categories={categories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
          />
        </div>
      )}
    </>
  );
};

export default Category;
