import React, { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

const faqData = [
  {
    question: "How to Select a Genuine Exporter?",
    answer: `A. Understand the exporter’s company: When did the company start?
    What is the background? Do they have any vision? Do they have the
    website? Please understand the content in the company website.
    Please focus on content and not on beautification of the site. Do
    they have any complaints in google? If there are, please cross
    check with the exporter to know the real facts and understand the
    case.`,
  },
  {
    question: "Tell me something about your company history?",
    answer: `A. Operose stands for extremes in Health, Happiness and
    Spirituality. The Team at Operose Global Care (KGC) believes that “
    to care is to be committed”. And caring for human well being makes
    it even more challenging in the current scenario of environmental
    hazards and dynamic lifestyle of today’s generation. KGC is formed
    with an objective of making the best quality Agriculture, Spices
    and Herbs care Products available to all people globally at the
    most reasonable prices.We strive to deliver excellence in every
    aspect of the business right from the beginning. Prompt response
    to Enquiries, complete information and details for all customer
    questions & queries, regular connect and updates to the customer
    till the time shipment reaches destination port and product
    feedback is received.`,
  },
  {
    question: "What is your vision and mission of the company?",
    answer: `Vision: To be the most consistently preferred exporter of Agro
    Products (Spices, Herbs, Agro Commodities, Fruits and Vegetables)
    in terms of quality and affordability across the Globe. Mission:
    To supply Indian premium Agro Products (Spices, Herbs, Agro
    Commodities, Fruits and Vegetables) in every country. Core Values:
    Quality, Integrity, Accuracy, Commitment & Collaboration(QIACC)`,
  },
  {
    question: "Where is your company based in India?",
    answer: `Our company is based out of Mumbai in India. Mumbai is the
    Financial Capital of India and It has access to all the Agro
    Products (Spices, Herbs, Agro Commodities, Fruits and Vegetables)
    in the country. Mumbai has the largest sea port in India. We are
    located near to the sea port. This gives us an advantage of being
    able to connect directly from all the producers for our face to
    face discussions to help the industry to develop high quality
    products. All the thoughts and with the same mindsets together
    make great industry.`,
  },
  {
    question:
      "Do you manufacture all the products displayed on the website in Agro Products (Spices, Herbs, Agro Commodities, Fruits and Vegetables)?",
    answer: `We are directly associated with farmers and sourcing agencies in
    agriculture. We have a team which directly go and check the
    quality of agro products all over the country. We only deal with
    the agro products which are famous in India and have the exports
    quality. We would love to manufacture all the products however as
    you would understand and agree that it is not feasible to
    manufacture all products. We also carry contract manufacturing in
    some places. We have a direct sourcing team which takes complete
    care in identifying the best manufacturing units who could not do
    marketing and do not have the manpower to take care. Our aim is to
    help them and connect global customers with quality products at an
    affordable prices.`,
  },
  {
    question:
      "Can you offer other products in Agro Products (Spices, Herbs, Agro Commodities, Fruits and Vegetables) industry apart from your list in the website?",
    answer: `If you need a product which is not mentioned at our site, you can
    let us know the product details you are interested in and we will
    guide you if the product is worth importing from India because we
    believe in giving only quality products which are worth to export.
    So we want our importers to be happy. We will provide guidance
    accordingly. Our motto is spreading the smiles across the globe.`,
  },
  {
    question: "What are your payment terms?",
    answer: `A. All payment are accepted in US dollars, unless otherwise
    specified in the quotation sent to you. Following are the payment
    options: Full Advance Payment: This method is the most convenient
    for small orders as it minimizes the bank charges involved in
    other methods of payment. You may send Telegraphic Transfer (TT)
    or Wire Transfer to our Bankers under intimation to us. Part
    Payment in Advance: For larger orders, we expect that you send 30%
    value in advance. Balance payment is payable before the dispatch.
    This method involves Bank Charges which are payable by the
    customer. Letter of Credit: We expect Sight Letter of Credit
    against bulk orders. This method involves considerable Bank
    charges. All Bank Charges as per the terms of Letter of Credit,
    must be to the account of the buyer. Small Payments: We accept
    small payment by Western Union also. We are also trying to launch
    Paypal feature so that customers can pay through the credit cards.`,
  },
  {
    question:
      "How can I be sure to get the product after making advance payment to you?",
    answer: `We are a Private Limited Company Registered in India. In this age
    of internet one can always check the authenticity of the company
    through various portals. We will be more than happy to assist you
    with any information or details which you need from our end to
    satisfy your concerns. We are very transparent in dealing. We
    mention everything clearly before dealing with the customer to
    reduce the communication gaps. We need buyers and we have a vision
    to achieve in long run. We need references from you to make our
    vision achievable.`,
  },
  {
    question: "Please give some information on Shipping mode and charges?",
    answer: `Normal mode of shipment are – “Sea Freight” and “Air Freight”.
    However we also use Courier, Post, EMS etc. upon requests from
    customers. It is your option to ask for prices inclusive of
    shipping costs or we can send the goods on “Freight To Collect
    basis”. The shipping charges may vary according to the size of
    shipment, destination and Mode of dispatch. C.I.F. rates are
    quoted if you inform us the exact quantity, destination and mode
    of dispatch preferred by you.`,
  },
  {
    question: "How can I get a Quote / Price List / Catalogue?",
    answer: `Please fill the Request for Quotation Form or mail us your
    requirement.`,
  },
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="w-full">
        <div
          className="bg-cover bg-center h-44 flex justify-center items-center"
          style={{
            backgroundImage: 'url("/footer.png")',
          }}
        >
          <div className="flex justify-evenly text-[rgb(255,255,0)] mt-4 mb-4 text-lg ml-5 sm:text-xl">
            WE ARE PROACTIVE IN GIVING CLARITY BEFORE SOMEBODY ASKS
          </div>
        </div>
        <h1 className="text-center text-4xl m-6">
          <b>
            <u>Frequently Asked Questions</u>
          </b>
        </h1>

        {faqData.map((item, index) => (
          <div
          onClick={() => toggleFaq(index)}
            key={index}
            className="w-[80%] text-justify font-semibold m-auto bg-[#daf9cf] text-black p-2 mb-4"
          >
            <div className="flex justify-between text-md sm:text-xl">
              {index + 1}. {item.question}
              <button onClick={() => toggleFaq(index)} className="ml-5">
                {activeIndex === index ? <FaMinus /> : <FaPlus />}
              </button>
            </div>
            <div className={`${activeIndex === index ? "" : "hidden"}`}>
              <p className="text-justify text-xs sm:text-sm my-3 w-[95%] m-auto">
                {item.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Faq;
