/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const TestimoniModal = ({
  setShowModel,
  id,
  setCurrentTestimonialId,
  getAllData,
}) => {
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [content, setContent] = useState("");
  const [rating, setRating] = useState("0");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImage(URL.createObjectURL(selectedFile));
    }
  };

  useEffect(() => {
    if (id) {
      const options = {
        method: "GET",
        url: `${backend_url}/testimony/get-testimony`,
        params: { testimony_id: id },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          setName(response.data.person_name);
          setDesignation(response.data.designation);
          setCompany(response.data.company);
          setContent(response.data.content);
          setRating(response.data.rating);
          setImage(response.data.testimonial_image);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, []);

  const handleCreate = () => {
    if (!file) {
      toast.error("Please select an image");
      return;
    }
    if (!name) {
      toast.error("Please enter name");
      return;
    }
    if (!designation) {
      toast.error("Please enter designation");
      return;
    }
    if (!company) {
      toast.error("Please enter company");
      return;
    }
    if (!content) {
      toast.error("Please enter content");
      return;
    }
    if (!rating) {
      toast.error("Please enter rating");
      return;
    }

    setLoading(true);
    const form = new FormData();
    form.append("image", file);
    const options = {
      method: "POST",
      url: `${backend_url}/testimony/create-testimonial`,
      params: {
        person_name: name,
        designation: designation,
        company: company,
        content: content,
        rating: rating,
      },
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message);
        setShowModel(false);
        getAllData();
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };
  const close = () => {
    setShowModel(false);
    setCurrentTestimonialId(null);
  };

  const Edit = () => {
    setLoading(true);
    const form = new FormData();
    if (file !== null) {
      form.append("image", file);
    } else {
      form.append("image", "");
    }

    // console.log(name);
    // console.log(file);

    const options = {
      method: "PATCH",
      url: `${backend_url}/testimony/edit-testimonial/${id}`,
      params: {
        person_name: name,
        designation: designation,
        company: company,
        content: content,
        rating: rating,
      },
      headers: { "Content-Type": "multipart/form-data" }, // Optional: axios will set this automatically
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message);
        setShowModel(false);
        getAllData();
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="fixed inset-0 flex  justify-center z-50">
        <div className="absolute inset-0 bg-black/60 opacity-75 "></div>
        <div
          className={`bg-white rounded-md z-50 self-start  min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-8  sm:mr-20 px-4 relative`}
        >
          <button className="absolute right-3 top-3   font-bold text-xl">
            <RxCrossCircled onClick={close} />
          </button>

          <div className="w-full mt-5  flex justify-center items-center">
            <div className="w-[400px] flex  flex-col gap-2">
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Name:
                </label>
                <input
                  type="text"
                  value={name}
                  id=""
                  className="border p-2"
                  onChange={(e) =>
                    setName(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                />
              </div>
              <div className="flex flex-col">
                <label for="designation" className="font-bold">
                  Designation:
                </label>
                <input
                  type="text"
                  value={designation}
                  className="border p-2"
                  onChange={(e) =>
                    setDesignation(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                />
              </div>
              <div className="flex flex-col">
                <label for="company" className="font-bold">
                  Company:
                </label>
                <input
                  type="text"
                  value={company}
                  className="border p-2"
                  onChange={(e) =>
                    setCompany(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                />
              </div>
              <div className="flex flex-col">
                <label for="content" className="font-bold">
                  Content:
                </label>
                <textarea
                  type="text"
                  value={content}
                  id=""
                  className="border p-2"
                  onChange={(e) =>
                    setContent(
                      e.target.value.replace(/\b\w/g, (c) => c.toUpperCase())
                    )
                  }
                />
              </div>
              <div className="flex flex-col">
                <label for="rating" className="font-bold">
                  Rating:
                </label>
                {/* <input
                  type="text"
                  value={rating}
                  id=""
                  className="border p-2"
                  onChange={(e) => setRating(e.target.value)}
                /> */}
                <select
                  name=""
                  id=""
                  value={rating}
                  className="border p-2 "
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="flex flex-col">
                <h2 className="font-semibold">Add Image:</h2>
                <input type="file" onChange={handleChange} />
                {file || image ? (
                  <img
                    src={image ? image : URL.createObjectURL(file)}
                    alt="Image"
                    style={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                      background: "cover",
                      marginTop: "5px",
                    }}
                  />
                ) : null}
              </div>
              <div className="flex justify-end ">
                {id ? (
                  loading ? (
                    <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                      Loading
                    </button>
                  ) : (
                    <button
                      onClick={Edit}
                      className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                    >
                      Save Changes
                    </button>
                  )
                ) : loading ? (
                  <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                    loading
                  </button>
                ) : (
                  <button
                    onClick={handleCreate}
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  >
                    Add Testimony
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimoniModal;
