import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { SlLogout } from "react-icons/sl";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();
  const { pathname } = location;


  const RenoveAdminId = () => {
    localStorage.removeItem("adminId");
    navigate("/login")
  }
  return (
    <>
      <div className=" border-b-2 py-4">
        <div className="flex  m-auto justify-center ">
          <div className=" mx-5 sm:ml-7 xl:mx-0 flex justify-between  items-center  w-full lg:w-[90px] ">
            {/* <div className=" "> */}
            <a
              href="/admin"
              className="   2xl:ml-5  text-2xl font-semibold text-pink-500 "
            >
              Operose
            </a>
            <button
              className="lg:hidden text-2xl"
              onClick={() => setShowMenu(!showMenu)}
            >
              <GiHamburgerMenu />
            </button>
          </div>

          <div className=" ml-5 xl:ml-7 px- hidden xl:w-[1160px] lg:flex text-sm xl:text-base ">
            <a
              href="/admin"
              className={` py-1 px-4 text-center ${pathname === "/admin" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Dashboard
            </a>
            <a
              href="/admin/tradeshow"
              className={` py-1 px-4 text-center ${pathname === "/admin/tradeshow" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Trade Show
            </a>
            <a
              href="/admin/gallery"
              className={` py-1 px-4 text-center ${pathname === "/admin/gallery" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Gallery
            </a>
            <a
              href="/admin/catalogue"
              className={` py-1 px-4 text-center ${pathname === "/admin/catalogue" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Catalogue
            </a>
            <a
              href="/admin/updates"
              className={` py-1 px-4 text-center ${pathname === "/admin/updates" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Updates
            </a>
            <a
              href="/admin/testimonial"
              className={` py-1 px-4 text-center ${pathname === "/admin/testimonial"
                ? "bg-gray-800 text-white"
                : ""
                } `}
            >
              TestiMonial
            </a>
            <a
              href="/admin/category"
              className={` py-1 px-4 text-center ${pathname === "/admin/category" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Category
            </a>
            <a
              href="/admin/commodities"
              className={` py-1 px-4 text-center ${pathname === "/admin/commodities"
                ? "bg-gray-800 text-white"
                : ""
                } `}
            >
              Commodities
            </a>
            <a
              href="/admin/contact-us"
              className={` py-1 px-4 text-center ${pathname === "/admin/contact-us" ? "bg-gray-800 text-white" : ""
                } `}
            >
              Contact Us
            </a>
            <a
              href="/admin/business-query"
              className={` py-1 px-4 text-center ${pathname === "/admin/business-query"
                ? "bg-gray-800 text-white"
                : ""
                } `}
            >
              Business Query
            </a>
            <button
              className="rotate-180" onClick={RenoveAdminId}>
              <SlLogout />

            </button>
          </div>

          {showMenu && (
            <div className="p-2 fixed top-16 left-0 bg-gray-300 z-10">
              <div className="  flex flex-col gap-x-5 gap-y-3   lg:hidden  text-sm xl:text-base bg-white  border-2 p-4 relative pt-12">
                <button
                  className="text-2xl absolute top-2 right-2"
                  onClick={() => setShowMenu(false)}
                >
                  <IoMdClose />
                </button>
                <a
                  href="/admin"
                  className={` py-1 px-4 text-center ${pathname === "/admin"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Dashboard
                </a>
                <a
                  href="/admin/tradeshow"
                  className={` py-1 px-4 text-center ${pathname === "/admin/tradeshow"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Trade Show
                </a>
                <a
                  href="/admin/gallery"
                  className={` py-1 px-4 text-center ${pathname === "/admin/gallery"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Gallery
                </a>
                <a
                  href="/admin/catalogue"
                  className={` py-1 px-4 text-center ${pathname === "/admin/catalogue"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Catalogue
                </a>
                <a
                  href="/admin/updates"
                  className={` py-1 px-4 text-center ${pathname === "/admin/updates"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Updates
                </a>
                <a
                  href="/admin/testimonial"
                  className={` py-1 px-4 text-center ${pathname === "/admin/testimonial"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  TestiMonial
                </a>
                <a
                  href="/admin/category"
                  className={` py-1 px-4 text-center ${pathname === "/admin/category"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Category
                </a>
                <a
                  href="/admin/commodities"
                  className={` py-1 px-4 text-center ${pathname === "/admin/commodities"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Commodities
                </a>
                <a
                  href="/admin/contact-us"
                  className={` py-1 px-4 text-center ${pathname === "/admin/contact-us"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Contact Us
                </a>
                <a
                  href="/admin/business-query"
                  className={` py-1 px-4 text-center ${pathname === "/admin/business-query"
                    ? "bg-gray-800 text-white"
                    : "border-2"
                    } `}
                >
                  Business Query
                </a>
                <button
                  className="rotate-180" onClick={RenoveAdminId}>
                  <SlLogout />

                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
