import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaFilePdf } from "react-icons/fa";

import { backend_url } from "../App";

const Catalogues = () => {
  const [allCatalogue, setAllCatalogue] = useState([]);
  const [selectCatalogue, setSelectCatalogue] = useState(null);

  useEffect(() => {
    getAllCatalogue();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (allCatalogue.length > 0) {
      setSelectCatalogue(allCatalogue[0]);
    }
  }, [allCatalogue]);

  const getAllCatalogue = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/catalogue/get-all-catalogues`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setAllCatalogue(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleUpdateClick = (catalogue) => {
    setSelectCatalogue(catalogue);
  };

  return (
    <div className="flex flex-col lg:flex lg:flex-row w-full gap-5  p-10 sm:pl-16  sm:pr-16 sm:pt-10 lg:p-5 xl:pl-24 xl:pr-24 xl:p-5">
      <div className="w-full lg:w-[80%] p-2 ">
        {selectCatalogue ? (
          <div className=" w-full  " key={selectCatalogue.id}>
            <h1 className="text-3xl font-semibold  text-gray-900">
              {selectCatalogue.catalogue_title}
            </h1>

            <div className="w-full mt-5 ">
              <img
                src={selectCatalogue.image_url}
                alt="pic"
                className="  w-full h-[500px] shadow-md mt-2 object-contain bg-gray-100"
              />
            </div>

            <div className="flex flex-col lg:flex lg:flex-row gap-5 mt-5">
              <p
                id="message"
                className="block p-2 w-[100%] text-lg text-gray-800 font-medium"
              >
                {selectCatalogue.catalogue_description}
              </p>
            </div>

            <div className="mt-4">
              {selectCatalogue.pdf_url && (
                <a
                  className="text-5xl  text-red-600"
                  href={selectCatalogue.pdf_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFilePdf />
                </a>
              )}
            </div>
            <div className="mt-2">
              <object
                data={selectCatalogue.pdf_url}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p>
                  Alternative text - include a link{" "}
                  <a href={selectCatalogue.pdf_url}>to the PDF!</a>
                </p>
              </object>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      <div className="w-full lg:w-[20%]  mt-5">
        <ul className="flex flex-col  gap-3">
          {allCatalogue.map((catalogue) => (
            <button
              key={catalogue.id}
              onClick={() => handleUpdateClick(catalogue)}
            >
              <li
                className={`py-3 bg-gray-200 pl-10 text-[17px] border-l-4 text-left hover:bg-gray-300 ${
                  selectCatalogue && selectCatalogue.id === catalogue.id
                    ? "border-[#E8D88F]"
                    : "border-green-800"
                }`}
              >
                {catalogue.catalogue_title}
              </li>
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Catalogues;
