/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { MultiSelect } from "react-multi-select-component";
import { backend_url } from "../../App";

const Catmodal = ({
  handleModal,
  tab,
  setShowModal,
  getData,
  categoryId,
  categories,
  setCategoryId,
}) => {
  const options = [
    { label: "Commodity 1", value: "Commodity 1" },
    { label: "Commodity 2", value: "Commodity 2" },
    { label: "Commodity 3", value: "Commodity 3" },
  ];

  const currentTimeFormatted = dayjs().format("YYYY-MM-DD HH:mm:ss");
  const [name, setName] = useState("");
  const [rank, setRank] = useState(0);
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createrOn, setCreatedOn] = useState(currentTimeFormatted);
  const [file, setFile] = useState(null); // Add file state

  useEffect(() => {
    if (categoryId) {
      const options = {
        method: "GET",
        url: `${backend_url}/category/get-category`,
        params: { category_id: categoryId },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          setName(response.data.category_name);
          setRank(response.data.category_rank);
          setImage(response.data.category_image_url);
          setSelectedCommodities(response.data.commodities);
          const formattedDate = dayjs(response.data.created_on).format(
            "YYYY-MM-DD"
          );
          setCreatedOn(formattedDate);

          // setCreatedOn(response.data.created_on);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [categoryId]);

  const close = () => {
    setCategoryId(null);
    setShowModal(false);
  };

  const editCategory = () => {
    const options = {
      method: "PATCH",
      url: `${backend_url}/category/edit-category/${categoryId}`,
      headers: { "Content-Type": "application/json" },
      data: {
        category_name: name,
        category_rank: rank,
        category_image_url: image, // Keep the existing image URL if it's not changed
        commodities: selectedCommodities,
        created_on: createrOn,
      },
    };
    setLoading(true);

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        if (file) {
          uploadImage(categoryId);
        }
        toast.success(response.data.message);
        setShowModal(false);
        setLoading(false);
        setCategoryId(null);

        getData();
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };

  const uploadImage = (categoryId) => {
    setLoading(true);

    if (!categoryId || !file) {
      const missingFields = [];
      if (!categoryId) missingFields.push("categoryId");
      if (!file) missingFields.push("imageFile");

      toast.error(
        `Please fill in the following fields: ${missingFields.join(", ")}`
      );
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    const options = {
      method: "POST",
      url: `${backend_url}/category/upload-image`,
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      params: {
        category_id: categoryId,
      },
      data: formData,
    };

    axios
      .request(options)
      .then((response) => {
        toast.success("Successfully Submitted");
        // console.log(response.data);
        getData();
        setLoading(false);
        setShowModal(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to upload image");
        setLoading(false);
      });
  };

  const addCategory = () => {
    setLoading(true);
    const fields = { name };
    if (!name) {
      alert("Enter the category name");
      setLoading(false);
      return;
    }

    const emptyFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);
    if (emptyFields.length === 0) {
      const options = {
        method: "POST",
        url: `${backend_url}/category/add-category`,
        headers: { "Content-Type": "application/json" },
        data: {
          category_name: name,
          category_rank: rank,
          category_image_url: url,
          commodities: selectedCommodities,
          created_on: createrOn,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data, "iddd");
          setName("");
          setRank("");
          setUrl("");
          setCreatedOn("");
          setSelectedCommodities([]);
          uploadImage(response.data.category_id);
          setShowModal(false);
          // getData();

          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    } else {
      const missingFields = emptyFields.join(", ");
      toast.error(`Fill the required fields: ${missingFields}`);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex justify-center z-50 items-center">
        <div className="absolute inset-0 bg-black/60 opacity-75"></div>
        <div
          className={`bg-white rounded-md z-50 self-start min-h-[40vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-[20vh] sm:mr-20 px-4 relative`}
        >
          <button className="absolute right-3 top-3 font-bold text-xl">
            <RxCrossCircled onClick={close} />
          </button>

          <div className="w-full mt-2 flex justify-center items-center">
            <div className=" flex w-[400px] flex-col gap-4">
              <div className="flex  flex-col">
                <label htmlFor="" className="font-bold">
                  Name:
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  className="border p-2"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {/* <div className="flex flex-col">
                <label htmlFor="rating" className="font-bold">
                  Rank:
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  className="border p-2"
                  value={rank}
                  onChange={(e) => setRank(e.target.value)}
                />
                <select
                  name=""
                  id=""
                  value={rank}
                  className="border p-2 "
                  onChange={(e) => setRank(e.target.value)}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div> */}

              {/* {categoryId ? (
                ""
              ) : (
                <div className="flex flex-col">
                  <label htmlFor="" className="font-bold">
                    Commodity:
                  </label>
                  <MultiSelect
                    options={options}
                    value={selectedCommodities}
                    onChange={setSelectedCommodities}
                    labelledBy="Select"
                  />
                </div>
              )}

              {categoryId ? (
                ""
              ) : (
                <div className="flex flex-col">
                  <label htmlFor="url" className="font-bold">
                    Image URL:
                  </label>
                  <input
                    type="url"
                    name="url"
                    id="url"
                    className="border p-2"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              )} */}

              {/* <div className="flex flex-col w-40">
                <label className="font-bold">Created At:</label>
                <input
                  type="date"
                  name=""
                  className="border p-2"
                  value={createrOn}
                  onChange={(e) => setCreatedOn(e.target.value)}
                />
                <div className="flex w-full gap-3"></div>
              </div> */}

              <div className="flex flex-col">
                <h2 className="font-semibold">Add Image:</h2>
                <input
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {file || image ? (
                  <img
                    src={file ? URL.createObjectURL(file) : image}
                    alt="Image"
                    style={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                      background: "cover",
                      marginTop: "5px",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="flex justify-end">
                {categoryId ? (
                  loading ? (
                    <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                      Loading...
                    </button>
                  ) : (
                    <button
                      onClick={editCategory}
                      className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                    >
                      Save Changes
                    </button>
                  )
                ) : loading ? (
                  <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                    loading
                  </button>
                ) : (
                  <button
                    onClick={addCategory}
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  >
                    Add Category
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Catmodal;
