import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaFilePdf } from "react-icons/fa";

import { backend_url } from "../App";

const Update = () => {
  const [updates, setUpdates] = useState([]);
  const [selectedUpdate, setSelectedUpdate] = useState(null);

  useEffect(() => {
    getAllUpdates();
  }, []);

  useEffect(() => {
    if (updates.length > 0) {
      setSelectedUpdate(updates[0]);
    }
  }, [updates]);

  const getAllUpdates = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/update/get-all-updates`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setUpdates(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleUpdateClick = (update) => {
    setSelectedUpdate(update);
  };

  return (
    <div className=" flex flex-col lg:flex lg:flex-row w-full gap-5  p-10 sm:pl-16  sm:pr-16 sm:pt-10 lg:p-5 xl:pl-24 xl:pr-24 xl:p-5">
      <div className=" w-full lg:w-[80%] p-2 ">
        {selectedUpdate ? (
          <div className=" w-full  " key={selectedUpdate.id}>
            <h1 className="text-3xl font-semibold  text-gray-900">
              {selectedUpdate.title}
            </h1>
            <h1 className="text-lg font-medium mt-1 text-gray-600">
              {selectedUpdate.author}
            </h1>
            <div className="w-full mt-5 ">
              <img
                src={selectedUpdate.image_url}
                alt="pic"
                className="  w-full h-[500px] shadow-md mt-2 object-contain bg-gray-100"
              />
            </div>

            <div className=" flex flex-col lg:flex lg:flex-row gap-5 mt-5">
              <p
                id="message"
                className="block p-2 w-full lg:w-[60%] text-lg text-gray-800 font-medium"
              >
                {selectedUpdate.content}
              </p>
              <div className="bg-gray-200 w-full lg:w-[40%] h-60 flex justify-center items-center mt-2">
                {selectedUpdate.video_link && (
                  <iframe
                    title="video"
                    className="h-full  w-full  p-2 rounded-lg"
                    src={`https://www.youtube.com/embed/${selectedUpdate.video_link.substring(
                      17
                    )}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>
            <div className="flex">
              <h1 className="text-lg font-medium ml-2 mt-4">
                Website Link:{" "}
                {selectedUpdate.website_link && (
                  <a
                    href={selectedUpdate.website_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600"
                  >
                    {selectedUpdate.website_link}
                  </a>
                )}
              </h1>
            </div>
            <div className="mt-4">
              {selectedUpdate.pdf_doc_url && (
                <a
                  className="text-5xl  text-red-600"
                  href={selectedUpdate.pdf_doc_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFilePdf />
                </a>
              )}
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      <div className=" w-full lg:w-[20%]  mt-5">
        <ul className="flex flex-col  gap-3">
          {updates.map((update) => (
            <button key={update.id} onClick={() => handleUpdateClick(update)}>
              <li
                className={`py-3 bg-gray-200 pl-10 text-[17px] border-l-4  hover:bg-gray-300 ${
                  selectedUpdate && selectedUpdate.id === update.id
                    ? "border-[#E8D88F]"
                    : "border-green-800"
                }`}
              >
                {update.title}
              </li>
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Update;
