import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import toast from "react-hot-toast/headless";
import { backend_url } from "../../App.js";

const Modal = ({ setShowModal, getGalleryData, editId, setEditId }) => {
  const [imageTitle, setImageTitle] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editId) {
      getSingleGallery();
    }
  }, [editId]);

  const getSingleGallery = async () => {
    try {
      const options = {
        method: "GET",
        url: `${backend_url}/gallery/get-gallery`,
        params: {
          image_id: editId,
        },
      };

      const response = await axios(options);
      // console.log(response.data);
      setImageTitle(response.data.gallery_title);
      setImageDescription(response.data.gallery_desc);
      setImages(response.data.image_urls);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const handleImageFileChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles([...imageFiles, ...files]);
  };

  const removeImageFile = (index) => {
    const newFiles = [...imageFiles];
    newFiles.splice(index, 1);
    setImageFiles(newFiles);
  };

  const AddGallery = () => {
    setLoading(true);
    const form = new FormData();
    imageFiles.forEach((file) => {
      form.append("images", file);
    });

    const options = {
      method: "POST",
      url: `${backend_url}/gallery/upload-gallery-images`,
      params: {
        gallery_title: imageTitle,
        gallery_desc: imageDescription,
      },
      headers: { "Content-Type": "multipart/form-data" },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message);
        setImageFiles([]);
        setImageTitle("");
        setImageDescription("");
        setShowModal(false);
        getGalleryData();
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        toast.error("Failed to upload images");
        setLoading(false);
      });
  };

  const editGalleryInfo = async () => {
    setLoading(true);
    // console.log(editId, imageTitle, imageDescription);
    try {
      const response = await axios.patch(
        `${backend_url}/gallery/edit-gallery-info/${editId}?gallery_title=${imageTitle}&gallery_desc=${imageDescription}`,

        {
          headers: {
            accept: "application/json",
          },
        }
      );
      // console.log(response.data);
      if (typeof imageFiles === "object" && imageFiles.length > 0) {
        editGalleryUploadImages();
      } else {
        getGalleryData();
        setShowModal();
        setEditId("");
        setLoading(false);
        toast.success(response.data.message);
      }

      // Handle response data here
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Handle error here
    }
  };

  const editGalleryUploadImages = async () => {
    try {
      const formData = new FormData();
      imageFiles.forEach((file) => {
        formData.append("images", file);
      });

      const options = {
        method: "PATCH",
        url: `${backend_url}/gallery/edit-gallery-upload-images/${editId}`,
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(options);
      // console.log(response.data);
      toast.success("Gallery uploaded successfully");
      getGalleryData();
      setShowModal(false);
      setEditId("");
      setLoading(false);
      // Handle response data here
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center z-50">
      <div className="absolute inset-0 bg-black/60 opacity-75"></div>
      <div className="bg-white rounded-md z-50 self-start min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-20 sm:mr-20 px-4 relative">
        <button
          className="absolute right-3 top-3 font-bold text-xl"
          onClick={() => {
            setShowModal(false);
            setEditId("");
          }}
        >
          <RxCrossCircled />
        </button>

        <div className="w-full mt-10 flex justify-center items-center">
          <div className="w-[400px] flex flex-col gap-4">
            <div className="flex flex-col">
              <label htmlFor="imageTitle" className="font-bold">
                Image Title:
              </label>
              <input
                type="text"
                id="imageTitle"
                className="border p-2"
                value={imageTitle}
                onChange={(e) => setImageTitle(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="imageDescription" className="font-bold">
                Image Description:
              </label>
              <input
                type="text"
                id="imageDescription"
                className="border p-2"
                value={imageDescription}
                onChange={(e) => setImageDescription(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="img" className="font-bold">
                Images:
              </label>
              <input
                type="file"
                id="img"
                className="border p-2"
                onChange={handleImageFileChange}
                multiple
              />
            </div>
            <div className="flex flex-col">
              <label className="font-bold">Selected Images:</label>
              <div className="flex w-full gap-3 flex-wrap">
                {imageFiles.map((file, index) => (
                  <div key={index} className="relative">
                    <div className="w-[70px] h-[70px]">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index}`}
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          background: "cover",
                        }}
                      />
                    </div>
                    <RxCrossCircled
                      className="absolute top-0 right-0 cursor-pointer"
                      onClick={() => removeImageFile(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
            {images.length > 0 && (
              <div className="flex flex-col">
                <label className="font-bold">Uploaded Images:</label>
                <div className="flex w-full gap-3 flex-wrap">
                  {images.map((image, index) => (
                    <div key={index} className="relative">
                      <div className="w-[70px] h-[70px]">
                        <img
                          src={image}
                          alt={`Image ${index}`}
                          style={{
                            width: "70px",
                            height: "70px",
                            objectFit: "cover",
                            background: "cover",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex justify-end">
              {loading ? (
                <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white">
                  Loading...
                </button>
              ) : editId ? (
                <button
                  className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white"
                  onClick={editGalleryInfo}
                >
                  Save changes
                </button>
              ) : (
                <button
                  className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border hover:text-white"
                  onClick={AddGallery}
                >
                  Add Gallery
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
