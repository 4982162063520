import React, { useEffect, useState } from "react";
import axios from "axios";
import { backend_url } from "../App";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const BusinessQuery = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [address, setAddress] = useState();
  const [details, setDetails] = useState();
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState();
  const [selectedCountryStates, setSelectedCountryStates] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  useEffect(() => {
    getCountries();
    window.scrollTo(0, 0);
  }, []);

  const validateMobile = (number) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(number);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const submitBusiness = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!validateMobile(number)) {
      setMobileError("Please enter a valid mobile number.");
      return;
    }
    const fields = {
      companyName,
      name,
      email,
      number,
      country,
      state,
      address,
      details,
      city,
    };
    const emptyFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (emptyFields.length === 0) {
      // All fields are filled, proceed with the submission
      const options = {
        method: "POST",
        url: `${backend_url}/business/submit-business-form`,
        headers: { "Content-Type": "application/json" },
        data: {
          company_name: companyName,
          contact_person: name,
          email: email,
          mobile: number,
          city: city,
          state: state,
          country: country,
          address: address,
          details: details,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          setAddress("");
          setCompanyName("");
          setName("");
          setEmail("");
          setNumber("");
          setDetails("");
          setCountry("");
          setState("");
          setCity("");
          toast.success(response.data.message);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      const missingFields = emptyFields.join(", ");
      toast.error(`Please fill in the following fields: ${missingFields}`);
    }
  };

  const getCountries = () => {
    const options = {
      method: "GET",
      url: "https://retpro.catax.me/get-all-countries",
    };

    axios
      .request(options)
      .then(function (response) {
        setCountries(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    if (country) {
      const selectedCountryObject = countries.find((c) => c.name === country);

      if (selectedCountryObject) {
        setSelectedCountryStates(selectedCountryObject.states);
      }
    } else {
      setSelectedCountryStates([]);
    }
  }, [country, countries]);

  return (
    <div className=" w-full border border-spacing-1 flex flex-col justify-center items-center">
      <h1 className="text-2xl font-semibold mt-10">Business Query</h1>
      <div className="flex justify-center flex-col gap-3 w-[70%] md:w-[700px] lg:w-[900px] mt-5 py-5">
        <div className="flex flex-col items-center sm:flex-row  gap-5">
          <div className="flex flex-col m-auto gap-2 w-[100%] sm:w-1/2">
            <label for="fname" className="font-semibold">
              Full name:
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter First Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border p-2 rounded-md bg-gray-200"
            />
          </div>
          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="company_name" className="font-semibold">
              Company name:
            </label>
            <input
              type="text"
              name="companyName"
              placeholder="Enter company name:"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="border p-2 rounded-md bg-gray-200"
            />
          </div>
        </div>
        <div className="flex flex-col items-center sm:flex-row  gap-5">
          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="email" className="font-semibold">
              Email:
            </label>
            <input
              type="Email"
              name="email"
              placeholder="Enter Email:"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              className="border p-2 rounded-md bg-gray-200"
            />
            {emailError && <p className="text-red-500">{emailError}</p>}
          </div>
          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="mobile" className="font-semibold ">
              Mobile No.:
            </label>
            <input
              type="text"
              name="title"
              placeholder="Enter Number:"
              maxLength={10}
              value={number}
              onChange={(e) => {
                // Remove any non-digit characters from the input
                const sanitizedValue = e.target.value.replace(/\D/g, "");
                setNumber(sanitizedValue);
                setMobileError("");
              }}
              className="border p-2 rounded-md bg-gray-200"
            />
            {mobileError && <p className="text-red-500">{mobileError}</p>}
          </div>
        </div>
        <div className="flex flex-col items-center sm:flex-row  w-full gap-5">
          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="mobile" className="font-semibold">
              Country:
            </label>
            {!countries || countries.length === 0 ? (
              <input
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="border p-2 rounded-md bg-gray-200"
              />
            ) : (
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="border p-2 rounded-md bg-gray-200"
              >
                <option value="" disabled>
                  Select country
                </option>
                {countries.map((c) => (
                  <option key={c.id} value={c.name}>
                    {c.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="mobile" className="font-semibold">
              State:
            </label>
            {!selectedCountryStates || selectedCountryStates.length === 0 ? (
              <input
                type="text"
                placeholder="State"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="border p-2 rounded-md bg-gray-200"
              />
            ) : (
              <select
                value={state}
                onChange={(e) => setState(e.target.value)}
                className=" border p-2 rounded-md bg-gray-200"
              >
                {selectedCountryStates && (
                  <>
                    <option value="" disabled selected>
                      Select State
                    </option>
                    {selectedCountryStates.map((s) => (
                      <option key={s.id} value={s.name}>
                        {s.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center sm:flex-row  w-full gap-5">
          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="mobile" className="font-semibold">
              City:
            </label>
            <input
              type="city"
              name="address"
              placeholder="Enter City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="border p-2 rounded-md bg-gray-200"
            />
          </div>

          <div className="flex flex-col gap-2 w-[100%] sm:w-1/2">
            <label for="address" className="font-semibold">
              Address:
            </label>

            <input
              type="text"
              name="address"
              placeholder="Enter address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="border p-2 rounded-md bg-gray-200"
            />
          </div>
        </div>
        <div className="flex sm:m-0 m-auto flex-col gap-2 w-[100%] sm:w-1/2">
          <label for="details" className="font-semibold">
            Details:
          </label>
          <textarea
            type="text"
            name="details"
            placeholder="Enter message"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            className="border p-2 rounded-md bg-gray-200"
          />
        </div>

        <div className="flex justify-center m-auto gap-2 sm:w-1/2">
          <button
            className="py-3 px-12 bg-[#241750] hover:bg-[#011936] rounded-md border text-white "
            onClick={submitBusiness}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessQuery;
