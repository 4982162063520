import React, { useEffect, useState } from "react";
import { FaFileDownload } from "react-icons/fa";

const downloadPdf = () => {
  const pdfPath = "/dumy_pdf.pdf";

  const link = document.createElement("a");

  // Set its href attribute to the path of the PDF
  link.href = pdfPath;

  // Set the download attribute to force download the file
  link.setAttribute("download", "example.pdf");

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger a click event on the link
  link.click();

  // Clean up: remove the link from the DOM
  document.body.removeChild(link);
};

const About = () => {
  const [Catalogue, setCatalogue] = useState("");
  const [Cata, setCata] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="min-h-[80vh] m-auto">
        <div
          className="bg-cover bg-center h-44 flex justify-center items-center"
          style={{
            backgroundImage: 'url("footer.png")',
          }}
        >
          <div className="text-2xl text-[#ffff00] pl-6">
            WE ARE TRADING PEOPLE WITH MODERN THOUGHTS
          </div>
        </div>

        <div className="flex w-[91%] sm:w-[80%]  m-auto  flex-wrap md:flex-nowrap">
          <div
            className="shadow-2xl w-[100%] h-[123px] mx-4 my-10 md:my-0 rounded-md py-5 px-5"
            onMouseEnter={() => setCata("product")}
            onMouseLeave={() => setCata("")}
          >
            <FaFileDownload
              // className=" group-hover:bg-orange-400 group-hover:text-white shadow-lg  w-[52px] h-[45px] rounded-md flex items-center justify-center p-2 -mt-10"
              // onClick={downloadPdf}
              className={`${
                Cata === "product" ? "bg-[#ff7800] text-white" : "bg-white"
              }  w-[52px] h-[45px] rounded-md flex shadow-lg items-center justify-center p-2 -mt-10 cursor-pointer`}
              onClick={downloadPdf}
            />
            <h1 className="mt-2 text-lg sm:text-md lg:text-2xl">
              Download Company Catalogue
            </h1>
            <button
              className="pt-3 text-gray-600 text-md sm:text-md"
              onClick={downloadPdf}
            >
              Click Here
            </button>
          </div>

          <div
            className="shadow-2xl w-[100%] h-[123px] mx-4 my-10 md:my-0 rounded-md py-5 px-5"
            onMouseEnter={() => setCatalogue("product")}
            onMouseLeave={() => setCatalogue("")}
          >
            <FaFileDownload
              className={`${
                Catalogue === "product" ? "bg-[#ff7800] text-white" : "bg-white"
              }  w-[52px] h-[45px] rounded-md flex shadow-lg items-center justify-center p-2 -mt-10 cursor-pointer`}
              onClick={downloadPdf}
            />
            <h1 className="mt-2 text-lg sm:text-md lg:text-2xl">
              Download Product Catalogue
            </h1>
            <button
              className="pt-3 text-gray-600 text-md sm:text-md"
              onClick={downloadPdf}
            >
              Click Here
            </button>
          </div>
        </div>
        <div className="sm:w-[76%] w-[80%] m-auto ">
          <h1 className="text-2xl font-semibold mt-10 mb-5 ">About Us</h1>
          <div className="m-auto mb-5">
            <p className="text-justify p-3">
              We are a group of people who are highly passionate towards our
              mission and our tagline “Miles of Smiles”, making the happiest
              place not only for our customers but also to everybody who are
              associated with Operose. One of the most important value to change
              any industry is Collaboration. We love the quote “You must be
              strong but we are Stronger”. We are building an amazing Team
              Culture which we learnt it hard from our experience. Our team is
              inspired to be real, face challenges, have fun and deliver
              excellence. We strive to create unique products and experiences
              for our customers and we thrive on creating innovative and happy
              experiences for our teams. Operose team is growing fast.
            </p>
          </div>

          <div className="m-auto mb-5">
            <p className="text-justify p-3">
              We are a group of people who are highly passionate towards our
              mission and our tagline “Miles of Smiles”, making the happiest
              place not only for our customers but also to everybody who are
              associated with Operose. One of the most important value to change
              any industry is Collaboration. We love the quote “You must be
              strong but we are Stronger”. We are building an amazing Team
              Culture which we learnt it hard from our experience. Our team is
              inspired to be real, face challenges, have fun and deliver
              excellence.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
