import React from "react";

const Catagories = () => {
  return (
    <div className="min-h-[80vh] flex justify-center items-center">
      <h1 className="text-2xl font-semibold">Catagories</h1>
    </div>
  );
};

export default Catagories;
