import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { backend_url } from "../App";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { BiSolidPhoneCall } from "react-icons/bi";

const Contact = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [purpose, setPurpose] = useState();
  const [mobile, setMobile] = useState();
  const [message, setMessage] = useState();
  const [country, setCountry] = useState();
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateMobile = (mobile) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendContact = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!validateMobile(mobile)) {
      setMobileError("Please enter a valid mobile number.");
      return;
    }
    const fields = { email, name, purpose, mobile, message, country };
    const inputFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (inputFields.length === 0) {
      const options = {
        method: "POST",
        url: `${backend_url}/contact/submit-contact-form`,
        headers: { "Content-Type": "application/json" },
        data: {
          full_name: name,
          purpose: purpose,
          message: message,
          email: email,
          mobile: mobile,
          country: country,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          toast.success(response.data.message);
          setCountry("");
          setMobile("");
          setMessage("");
          setPurpose("");
          setEmail("");
          setName("");
        })
        .catch(function (error) {
          console.error(error);
          toast.error(error);
        });
    } else {
      toast.error(inputFields.join(" "));
    }
  };

  return (
    <>
      <div className="h-64 w-full bg-[#011936] ">
        <div className="h-64 flex items-center w-[90%] m-auto ">
          <div className="">
            <h1 className="text-5xl text-white">
              <b>Contact Us</b>
            </h1>
            <h2 className="text-white text-xl font-medium">
              We are always ready to help you...
            </h2>
          </div>
        </div>
      </div>
      <div className="flex gap-10 w-full justify-center items-center min-h-[500px] flex-wrap mt-10 lg:mt-0">
        <div className="w-[318px] h-[306px] rounded-tl-[60px] rounded-br-[60px] hover:border-dashed hover:border-[#85b02c] border-2 hover:p-3">
          <div className="bg-[#85b02c] h-full w-full rounded-tl-[60px] rounded-br-[60px] pt-10 pl-2 ">
            <div className="w-[70px] h-[70px] bg-white rounded-full m-auto flex items-center justify-center">
              <FaMapMarkerAlt className="text-3xl text-[#85b02c]" />
            </div>
            <div className="text-white flex justify-center items-center text-xl py-2">
              Registered Address
            </div>
            <div className="text-white">
              Operose Global Care Pvt. Ltd., Unit 4/705, Plot No. 4, Sector 9E,
              Kalamboli, Navi Mumbai – 410218, Maharashtra, India.
            </div>
          </div>
        </div>
        <div className="w-[318px] h-[306px] rounded-tl-[60px] rounded-br-[60px] hover:border-dashed hover:border-[#85b02c] border-2 hover:p-3">
          <div className="bg-[#85b02c] h-full w-full rounded-tl-[60px] rounded-br-[60px] pt-10 pl-2 ">
            <div className="w-[70px] h-[70px] bg-white rounded-full m-auto flex items-center justify-center">
              <FiMapPin className="text-3xl text-[#85b02c]" />
            </div>
            <div className="text-white flex justify-center items-center text-xl py-2">
              Office Address
            </div>
            <div className="text-white">
              Operose Global Care Pvt. Ltd., Unit 4/705, Plot No. 4, Sector 9E,
              Kalamboli, Navi Mumbai – 410218, Maharashtra, India.
            </div>
          </div>
        </div>
        <div className="w-[318px] h-[306px] rounded-tl-[60px] rounded-br-[60px] hover:border-dashed hover:border-[#85b02c] border-2 hover:p-3">
          <div className="bg-[#85b02c] h-full w-full rounded-tl-[60px] rounded-br-[60px] pt-10 pl-2 ">
            <div className="w-[70px] h-[70px] bg-white rounded-full m-auto flex items-center justify-center">
              <BiSolidPhoneCall className="text-3xl text-[#85b02c]" />
            </div>
            <div className="text-white flex justify-center items-center text-xl py-2">
              Contact
            </div>
            <div className="text-white">
              Operose Global Care Pvt. Ltd., Unit 4/705, Plot No. 4, Sector 9E,
              Kalamboli, Navi Mumbai – 410218, Maharashtra, India.
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 w-full flex justify-center items-center ">
        <div className="w-[] m-auto">
          <h2 className="text-[#76a713] font-semibold text-md sm:text-xl text-center mb-2">
            ~Contact Us~
          </h2>
          <h1 className="sm:text-4xl text-xl text-center mb-10">
            Feel free to contact with us any time.
          </h1>
          <form className="w-full sm:flex justify-center flex-col">
            <div className="sm:flex-nowrap flex gap-6 justify-center items-center text-left flex-wrap">
              <di className="w-[80%]">
                <p className="my-1 font-semibold">Full name:</p>
                <input
                  className=" rounded-md bg-gray-200 w-full pl-2 sm:w-[299px] h-[40px]"
                  type="text"
                  name="title"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </di>
              <div className="w-[80%]">
                <p className="my-1 font-semibold">Mail Address:</p>
                <input
                  className="bg-gray-200  rounded-md pl-2 w-full sm:w-[299px] h-[40px]"
                  type="email"
                  name="email"
                  placeholder="Mail Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <p className="text-red-500">{emailError}</p>}
              </div>
            </div>
            <div className="sm:flex-nowrap flex gap-6 justify-center items-center text-left mt-5 flex-wrap">
              <div className="w-[80%]">
                <p className="my-1 font-semibold">Mobile:</p>
                {/* <input
                  className="bg-gray-200 pl-2 w-full sm:w-[299px] h-[36px]"
                  type="text"
                  name="title"
                  placeholder=""
                  value={mobile}
                  maxLength={10}
                  onChange={(e) => {
                    setMobile(e.target.value);
                    setMobileError("");
                  }} 
                /> */}
                <input
                  type="text"
                  pattern="[0-9]"
                  placeholder="Mobile Number"
                  value={mobile}
                  onChange={(e) => {
                    const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                    setMobile(onlyNum);
                    setMobileError("");
                  }}
                  maxLength={10}
                  className="bg-gray-200 rounded-md pl-2 w-full sm:w-[299px] h-[40px]"
                  tabIndex="0" // Make the div focusable
                />
                {mobileError && <p className="text-red-500">{mobileError}</p>}
              </div>
              <div className="w-[80%]">
                <p className="my-1 font-semibold">Country:</p>
                <input
                  className="bg-gray-200 pl-2 rounded-md w-full sm:w-[299px] h-[40px]"
                  type="text"
                  name="title"
                  placeholder="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
          </form>
          <div className="sm:w-[100%] w-[80%] m-auto my-8">
            <p className="my-1 m-auto font-semibold">Purpose:</p>
            <input
              className="bg-gray-200 rounded-md h-[50px] sm:w-full w-[100%] m-auto px-2"
              type="text"
              name="title"
              placeholder="Purpose"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
            />
          </div>
          <div className="">
            <div className="sm:w-[100%] w-[80%] m-auto">
              <p className="my-1 font-semibold">Message:</p>
              <textarea
                className="bg-gray-200 rounded-md pt-5 sm:w-full w-[100%] px-2"
                type="text"
                name="title"
                placeholder="Type your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center my-6">
        <button
          className="text-center text-white bg-[#241750] hover:bg-[#011936] rounded-md  w-[299px] sm:w-[620px] h-[58px]"
          onClick={sendContact}
        >
          {" "}
          Submit
        </button>
      </div>
    </>
  );
};
export default Contact;
