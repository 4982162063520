import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import Modal from "../components/Modal";
import axios from "axios";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [galleryId, setGalleryId] = useState("");
  const [editId, setEditId] = useState("");

  useEffect(() => {
    getGalleryData();
  }, []);

  const getGalleryData = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/gallery/get-all-gallery`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const deleteSingleImage = async () => {
    try {
      const options = {
        method: "DELETE",
        url: `${backend_url}/gallery/delete-image/${galleryId}`,
        params: {
          image_url: imageUrl,
        },
        headers: {
          accept: "application/json",
        },
      };

      const response = await axios.request(options);
      // console.log(response.data);
      toast.success(response.data.message);
      setGalleryId("");
      setImageUrl("");
      getGalleryData();
    } catch (error) {
      console.error("Failed to delete image", error);
      throw error;
    }
  };

  const deleteGallery = async () => {
    // console.log(galleryId);
    try {
      const options = {
        method: "DELETE",
        url: `${backend_url}/gallery/delete-images`,
        params: {
          gallery_id: galleryId,
        },
        headers: {
          accept: "application/json",
        },
      };
      const response = await axios.request(options);
      // console.log(response.data);
      toast.success(response.data.message);
      getGalleryData();
      setGalleryId("");
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  const handleDeleteCancel = () => {
    setImageUrl("");
    setGalleryId("");
  };

  const handleModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="pt-3 pb-5">
        <div className="w-full xl:w-[1100px] m-auto px-4 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-3 ">
            <h1 className="text-2xl font-semibold ">Gallery</h1>
            <button
              className="border-2 border-black px-5 text-lg h-9 font-semibold"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
          <div className="">
            {data.length > 0 ? (
              data.map((item) => (
                <div className="mb-6 pl-2" key={item.id}>
                  <div className="flex justify-between">
                    <div className="">
                      <h1 className="text-lg font-semibold mb-1">
                        {item.gallery_title}
                      </h1>
                      <p className="text-sm mb-1">{item.gallery_desc}</p>
                    </div>
                    <div className="">
                      <button
                        className="border-2 border-black px-5 text-lg h-9 font-semibold mr-2"
                        onClick={() => {
                          setEditId(item.id);
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="border-2 border-black px-5 text-lg h-9 font-semibold"
                        onClick={() => setGalleryId(item.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  {/* <p className="text-xs text-gray-500 mb-4">
                    Uploaded on:{" "}
                    {new Date(item.upload_time).toLocaleDateString()}
                  </p> */}
                  {item.image_urls && Array.isArray(item.image_urls) && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {item.image_urls.map((image, index) => (
                        <div className="relative w-full h-[250px]" key={index}>
                          <img
                            src={image}
                            alt=""
                            className="border-2 h-[250px] w-full object-cover"
                          />
                          <button
                            className="absolute right-2 top-2 text-white font-bold text-2xl"
                            onClick={() => {
                              setImageUrl(image);
                              setGalleryId(item.id);
                            }}
                          >
                            <RxCrossCircled color="black" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No galleries available.</p>
            )}
            {galleryId && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg">
                  <h2 className="text-lg font-semibold mb-4">
                    Are you sure you want to delete this{" "}
                    {galleryId && imageUrl ? "gallery image" : "gallery"}?
                  </h2>
                  <div className="flex justify-between">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                      onClick={() => {
                        galleryId && imageUrl
                          ? deleteSingleImage()
                          : deleteGallery();
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                      onClick={handleDeleteCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <div className="">
          <Modal
            Modal={handleModal}
            setShowModal={setShowModal}
            editId={editId}
            setEditId={setEditId}
            getGalleryData={getGalleryData}
          />
        </div>
      )}
    </>
  );
};

export default Gallery;
