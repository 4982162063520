import React, { useEffect } from "react";
import { AiFillLinkedin } from "react-icons/ai";

const People = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w-[90%] m-auto">
        <h1
          className="text-3xl font-semibold mt-10 flex justify-start m-auto
        "
        >
          People
        </h1>
        <div className=" flex gap-5 lg:flex-nowrap flex-wrap lg:mt-5 mt-10 item-center justify-center border-2 px-5 py-5">
          <div className="mt-2">
            <img
              className="lg:h-[220px] md:h-[250px] md:w-[450px] lg:w-[300px] mb-5"
              decoding="async"
              width="300"
              height="200"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6mxx567xwCpGwK5ZXIAbQ5YC20f9Ul0UWzA&s"
              sizes="(max-width: 800px) 100vw, 800px"
            />
          </div>
          <div className="lg:w-3/4 mb-10">
            <div className="flex justify-between text-2xl lg:mb-2 mb-5">
              <h1 className="font-bold">Gal Gadot</h1>
              <div className="text-3xl">
                <AiFillLinkedin />
              </div>
            </div>
            <p className="text-justify">
              We understand the importance of approaching each work integrally
              and believe in the power of simple and easy communication. Our
              purpose is to build solutions that remove barriers preventing
              people from doing their best work, and this is at the
              heart.Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
              Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
              Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
        
        
        
            </p>
          </div>
        </div>
        <div className=" flex gap-5 lg:flex-nowrap flex-wrap lg:mt-5 mt-10 item-center justify-center border-2 px-5 py-5">
          <div className="mt-2">
            <img
              className="lg:h-[200px] md:h-[250px] md:w-[450px] lg:w-[300px] mb-5"
              decoding="async"
              width="300"
              height="200"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6mxx567xwCpGwK5ZXIAbQ5YC20f9Ul0UWzA&s"
              sizes="(max-width: 800px) 100vw, 800px"
            />
          </div>
          <div className="lg:w-3/4">
            <div className="flex justify-between text-2xl lg:mb-2 mb-5">
              <h1 className="font-bold">Gal Gadot</h1>
              <div className="text-3xl">
                <AiFillLinkedin />
              </div>
            </div>
            <p className="text-justify">
              We understand the importance of approaching each work integrally
              and believe in the power of simple and easy communication. Our
              purpose is to build solutions that remove barriers preventing
              people from doing their best work, and this is at the
              heart.Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
              Because curcumin and other chemicals in turmeric might decrease
              swelling, it is often used to treat conditions that involve pain
              and inflammation.
            </p>
          </div>
        </div>
        <div className=" flex gap-5 lg:flex-nowrap flex-wrap lg:mt-5 mt-10  item-center justify-center border-2 lg:px-5 lg:py-5 mb-10">
          <div className="mt-2 pt-2 md:pt-5">
            <img
              className="lg:h-[220px] md:h-[250px] md:w-[450px] lg:w-[300px] mb-5"
              decoding="async"
              width="300"
              height="200"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6mxx567xwCpGwK5ZXIAbQ5YC20f9Ul0UWzA&s"
              sizes="(max-width: 800px) 100vw, 800px"
            />
          </div>
          <div className="lg:w-3/4 px-5 py-5">
            <div className="flex justify-between text-2xl lg:mb-2 mb-5">
              <h1 className="font-bold">Gal Gadot</h1>
              <div className="text-3xl">
                <AiFillLinkedin />
              </div>
            </div>
            <p className="text-justify">
              We understand the importance of approaching each work integrally
              and believe in the power of simple and easy communication. Our
              purpose is to build solutions that remove barriers preventing
              people from doing their best work, and this is at the
              heart.Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
              Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
              Turmeric has a warm, bitter taste and is frequently used to
              flavor or color curry powders, mustards, butters, and cheeses.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default People;
