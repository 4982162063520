import React, { useEffect, useState } from "react";
import { BiCopyright, BiLogoFacebookCircle } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";
import { backend_url } from "../App";
import axios from "axios";
import { FaWhatsapp } from "react-icons/fa";


const Foot = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/category/get-all-category`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <>
      <footer>
        <div
          className="w-full flex-wrap flex flex-col sm:flex-row gap-10 text-white m-auto xl:justify-evenly pt-12 p-5 bg-no-repeat bg-cover object-fill"
          style={{
            backgroundImage:
              'url("/footer.png")',
          }}
        >
         
          <div className="">
          <div className="flex">
          <Link to="/" className="">
            <img
              src="/operose-removebg-preview.png"
              className=" w-[70px] mr-2"
              alt=""
            ></img>
            </Link>
              <p className="text-gray-400">
            
            Cross Check manages Public News Register™of media
            <br />
            industry to protect news supply chain, valuable assets, <br />
            enable cross verification and further digital journalism.
          </p>
         
        
        </div>
            <div className="flex  items-center gap-2 mt-4">
              {/* <div className=" flex justify-center items-center mr-2 text-md md:text-xl">
                <FaWhatsapp className="text-[#73b03c] block sm:text-xl md:text-2xl mr-1 hidden sm:block" />
                <a href="https://api.whatsapp.com/send?phone=919893600060&text=Hi!%20I'm%20want%20more%20information%20about%20import%20and%20export%20trade" target="blanck">
                  <p className="font-semibold mr-3 text-sm  md:text-xl hidden sm:block">
                    +91 98936 00060
                  </p>
                </a>
              </div> */}
              <div className="text-2xl md:text-3xl">
                <a href="https://www.facebook.com" target="blanck">
                  <BiLogoFacebookCircle className="" />
                </a>
              </div>
              <div className="text-2xl md:text-3xl">
                <a href="https://twitter.com" target="blanck">
                  <AiOutlineTwitter />
                </a>
              </div>
              <div className="text-2xl md:text-3xl">
                <a href="https://www.linkedin.com" target="blanck">
                  <AiFillLinkedin className="" />
                </a>
              </div>
              <div className="text-2xl md:text-3xl">
                <a href="https://www.youtube.com" target="blanck">
                  <AiFillYoutube className="" />
                </a>
              </div>
              <div className="text-2xl md:text-3xl">
                <a href="https://www.instagram.com" target="blanck">
                  <AiFillInstagram />
                </a>
              </div>
            </div>
            {/* <p className="font-light mt-2">
              © 2023 Kinal Global Care Private Limited , All Rights Reserved.
            </p> */}
          </div>
          <div className="grid sm:grid-cols-4 gap-5 grid-cols-2">
            <div className="flex flex-col">
              <h1 className="text-lg mb-3 font-medium">Company</h1>
              <Link to="/about" className="mb-2">
                About Rose
              </Link>
              <Link to="/people" className="mb-2">
                People
              </Link>
              <Link to="/why" className="mb-2">
                Why
              </Link>
            </div>
            <div className="flex flex-col">
              <h1 className="text-lg mb-3 font-medium">Catagories</h1>
              {categories.map((index) => (
                <div key={index.id}>
                  <Link to={`/product/${index.category_name}`}>
                    <h1 className=" mb-2">{index.category_name}</h1>
                  </Link>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              <h1 className="text-lg mb-3 font-medium">Other </h1>
              <Link to="/gallery" className="mb-2">
                Gallery
              </Link>

              <Link to="/Updatess" className="mb-2">
                Updates
              </Link>
              <Link to="/Catalogues" className="mb-2">
                Catalogue
              </Link>
              <Link to="/faq" className="mb-2">
                FAQ
              </Link>
            </div>
            <div className="flex flex-col">
              <h1 className="text-lg mb-3 font-medium">Contact</h1>
              <Link to="/contact" className="mb-2">
                Contact Us
              </Link>
              <Link to="/business-query" className="mb-2">
                Business Query
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Foot;
