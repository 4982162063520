/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { backend_url } from "../App";
import axios from "axios";

const Gallery1 = () => {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    getGallery();
    window.scrollTo(0, 0);
  }, []);

  const getGallery = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/gallery/get-all-gallery`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setGallery(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  // if (!imageUrls || imageUrls.length === 0) {
  //     return <div>No images to display</div>;
  //   }
  return (
    <>
      <div>
        <div
          className="bg-cover bg-center h-44 flex justify-center items-center"
          style={{
            backgroundImage:
              'url("/footer.png")',
          }}
        >
          <div className="text-2xl text-[#ffff00] pl-6 ">
            WE UNDERSTAND EVERY CONSUMER MARKET IN EVERY COUNTRY
          </div>
        </div>

        <div className="">
          <div className="  text-orange-600 w-[80%] text-xl m-auto font-semibold my-5">
            MARKET VISIT GALLERY
          </div>

          <div className="text-Black w-[80%] m-auto font-semibold my-5">
            {gallery.length > 0 ? (
              gallery.map((item) => (
                <div className="" key={item.id}>
                  <div className="">
                    <h1 className="text-xl font-semibold ">
                      {item.gallery_title}
                    </h1>
                    <p className=" font-medium mb-1">{item.gallery_desc}</p>
                  </div>

                  {item.image_urls && Array.isArray(item.image_urls) && (
                    <div className=" mt-2 mb-4  grid  w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4  m-auto ">
                      {item.image_urls.map((image, index) => (
                        <div className="relative w-full h-[250px]" key={index}>
                          <img
                            src={image}
                            alt=""
                            className="border-2 h-[250px] w-[350px] object-cover m-auto"
                          />
                          <button
                            className="absolute right-2 top-2 text-white font-bold text-2xl"
                            onClick={() => {}}
                          ></button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No galleries available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery1;
