import React, { useState, useEffect } from "react";
import axios from "axios";
import { backend_url } from "../../App";
import TestimoniModal from "../components/TestimoniModal";

import { TiStar } from "react-icons/ti";
import toast from "react-hot-toast";

const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [currentTestimonialId, setCurrentTestimonialId] = useState(null);

  useEffect(() => {
    getAllData();
  }, [deleteId]);

  const getAllData = () => {
    if (deleteId === null) {
      // handleDeleteConfirm(deleteId);
      setCurrentTestimonialId(null);
    }

    const options = {
      method: "GET",
      url: `${backend_url}/testimony/get-all-testimonial`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setTestimonial(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = (id) => {
    const options = {
      method: "DELETE",
      url: `${backend_url}/testimony/delete-testimonial/${id}`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message);
        // setTestimonial((prevTestimonials) =>
        //   prevTestimonials.filter((testimonial) => testimonial.id !== id)
        // );
        getAllData();
        handleDeleteCancel();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDeleteCancel = () => {
    setDeleteId(null);
  };

  const handleModel = () => {
    setTestimonial(true);
  };

  const edit = (id) => {
    setShowModel(true);
    setCurrentTestimonialId(id);
    // console.log(id);
  };
  return (
    <>
      <div className="pt-3 pb-5">
        <div className="w-full xl:w-[1100px] m-auto px-6 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold ">Testimonial</h1>
            <button
              className="border-2 border-black px-5 text-lg  h-9 font-semibold"
              onClick={() => setShowModel(true)}
            >
              Add
            </button>
          </div>
          <div className="grid  lg:grid-cols-2 gap-x-2 gap-y-4">
            {testimonial.map((index) => (
              <div className="border-2 p-4" key={index.id}>
                <div className="relative flex flex-col  sm:flex-row gap-4 items-center">
                  <img
                    src={index.testimonial_image}
                    alt=""
                    className="  w-[330px] sm:w-[150px]  h-[180px] sm:h-[150px]"
                  />
                  <div className="">
                    <h1 className=" font-semibold">
                      Name - {index.person_name}
                    </h1>
                    <h1 className="font-semibold">Company - {index.company}</h1>
                    <h1 className="font-semibold">{index.designation}</h1>
                    <p className="font-semibold">{index.content}</p>
                    <h1 className="text-sm">Rating: {index.rating}</h1>
                  </div>
                  <div className="flex gap-4 sm:gap-2 sm:absolute right-0 top-0">
                    <button
                      className="border-2 border-black px-10  sm:px-5 py-1 sm:py-0   font-semibold"
                      onClick={() => edit(index.id)}
                    >
                      Edit
                    </button>
                    <button
                      className="border-2 border-black px-10  sm:px-5 py-1 sm:py-0   font-semibold"
                      onClick={() => handleDelete(index.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {deleteId !== null && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">
              Are you sure you want to delete this testimonial?
            </h2>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                onClick={() => handleDeleteConfirm(deleteId)}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showModel && (
        <TestimoniModal
          Modal={handleModel}
          setShowModel={setShowModel}
          id={currentTestimonialId}
          setCurrentTestimonialId={setCurrentTestimonialId}
          getAllData={getAllData}
        />
      )}
    </>
  );
};

export default Testimonial;
