import React, { useEffect } from "react";
import { GiLindenLeaf } from "react-icons/gi";
import { IoReloadOutline } from "react-icons/io5";

const Why = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="bg-cover bg-center h-44 flex justify-center items-center"
        style={{
          backgroundImage: 'url("footer.png")',
        }}
      >
        <div className="text-2xl text-[#ffff00] pl-6">
          WE ARE TRADING PEOPLE WITH MODERN THOUGHTS
        </div>
      </div>
      <div className="my-20"></div>
      <div className="my-20 w-[90%] sm:w-[70%] m-auto">
        <div className="w-[100%] m-auto shadow-2xl py-5 px-5">
          <IoReloadOutline className="bg-[#ff7800] w-[52px] h-[45px] rounded-md flex items-center justify-center text-white p-2 -mt-10" />
          <h1 className=" text-xl my-3">The Gap</h1>
          <p className="text-gray-600">
            India is one of the largest producer of agriculture products for t
            he world and especially Spices is one of the biggest exporter of the
            world. There are lot of exporters in the market who do the business
            of exports in this category but very few had the courage to do
            passionately and professionally. There is a big gap of transparency,
            integrity and professionalism. You will see majority of the times a
            big gap between product of samples and real product. We would like
            to take pride in informing and have the courage to write it in the
            website that we deal with high integrity, professionalism and
            transparency of each and every deal. We deal with passion with a
            focused approach towards the industry and farmers. We give customer
            centric solutions and do not hurry up the orders. We always insist
            the customer and guide the customer irrespective of whether he gives
            business to us or not.
          </p>
        </div>
      </div>

      <div className="my-20 w-[90%] sm:w-[70%] m-auto">
        <div className="w-[100%] m-auto shadow-2xl py-5 px-5">
          <GiLindenLeaf className="bg-[#ff7800] w-[52px] h-[45px] rounded-md flex items-center justify-center text-white p-2 -mt-10" />

          <h1 className="text-xl my-3">What makes us special ?</h1>
          <p className="text-gray-600">
            Operose stands for extremes in Fortune, Health and Spirituality. Our
            main goal is to spread the smiles across the globe. We work with
            passion and have an approach of Relationship & Human Values First
            and Profit next. We really wanted to make people whoever is
            associated with us with fortune, health and happiness. We have
            absolutely fantastic associations with farmers and does export
            business with right attitude. Our core strength lies in
            understanding the subjects of agriculture and also understand the
            benefits of exporting the right product as per the importer’s
            standards. The first step for processing an agriculture product is
            right farming techniques and the next is the processing and quality
            management technique. Our philosophy is to keep our end user in mind
            while we process the raw materials. We are very sensitive towards
            confident reporting to our customers. We invest a considerable
            amount of time and efforts in identifying right source of the
            product that we export where we focus not only in development of our
            product and export market but also our people and their skill-sets.
            All our products have high regards which are acceptable across the
            globe. Our non-compromisable stringency in selection and
            optimization of Raw Materials, Packaging Materials and Processing
            Areas help us build confidence in our products. In agriculture
            business, we value the problems of the customers and give them
            considerable amount of confidence in dealing by adjusting our
            profits. We do not run away with problems if there is any quality
            issue. We will try to sort it and if needed we will reimburse the
            amount confidently with trust.
          </p>
        </div>
      </div>
      <div className="my-20 w-[90%] sm:w-[70%] m-auto">
        <div className="w-[100%] m-auto shadow-2xl py-5 px-5">
          <IoReloadOutline className="bg-[#ff7800] w-[52px] h-[45px] rounded-md flex items-center justify-center text-white p-2 -mt-10" />
          <h1 className="text-xl my-3">Our Track Record</h1>
          <p className="text-gray-600">
            We have a vast experience of farming and understanding the product
            with right sourcing. We have established ourselves as one of the
            trusted and honest exporters since the start of the company. We have
            been recognised as one of the most trustworthy exporter in India by
            not only our foreign clients but also our vendors who are associated
            with us. We do not over trade the business and also work with thin
            margins by giving benefit to the end user and customer. Ours is the
            company where we align our organisational goal with our Vision,
            Mission and core values, Over the years, Operose Global Care Pvt Ltd
            has established itself as one of the most reliable export partner
            for importers all over the globe. Currently, we export to close to
            17 countries with 86 products. Our 90% successful retention
            indicates how we value the relationship. In short, Operose Global
            Care Private Limited has shown successful track record of exporting
            agro products that are satisfied across the globe. Quality Control
            Specialist in each process before the order Procurement and
            scrutinity to the fullest from farmers/ processor before the order
            Quality Control Specialist in each process during the order
            .Specialist (If required agro scientist/ Quality management guy)
            will deal throughout the process. Direct involvement of Operose
            management for each shipment. Adhering to our core values for each
            process.{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Why;
