import React, { useEffect, useState } from "react";
import CatalogueModal from "../components/CatalogueModal";
import { backend_url } from "../../App";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";

const downloadPdf = (path) => {
  // Path to your PDF file
  const pdfPath = path;

  // Create a link element
  const link = document.createElement("a");

  // Set its href attribute to the path of the PDF
  link.href = pdfPath;

  // Set the download attribute to force download the file
  link.setAttribute("download", "example.pdf");

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger a click event on the link
  link.click();

  // Clean up: remove the link from the DOM
  document.body.removeChild(link);
};

const Catalogue = () => {
  const [showModal, setShowModal] = useState(false);
  const [allCatalogue, setAllCatalogue] = useState([]);
  const [deleteCatalog, setDeleteCatalog] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getAllCatalogueData();
  }, []);

  const getAllCatalogueData = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/catalogue/get-all-catalogues`,
    };

    axios
      .request(options)
      .then(function (response) {
        setAllCatalogue(response.data);
        // console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDeleteConfirm = () => {
    const url = `${backend_url}/catalogue/delete-catalogue/${deleteCatalog}`;

    axios
      .delete(url, {
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data);
        toast.success("Catalogue deleted successfully!");
        //  if (onSuccess) onSuccess();
        getAllCatalogueData();
        setDeleteCatalog(null);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to delete catalogue");
      });
  };

  const handleShowModal = (id) => {
    if (id) {
      setEditId(id);
    }

    setShowModal(true);
  };

  return (
    <>
      <div className="pt-3 pb-5">
        <div className="w-full xl:w-[1100px] m-auto px-6 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold">Catalogue</h1>
            <button
              className="border-2 border-black px-5 text-lg h-9 font-semibold"
              onClick={handleShowModal}
            >
              Add
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-7 gap-y-5">
            {allCatalogue.map((data) => (
              <div
                className="border-2 border-black w-full flex flex-col p-2 relative"
                key={data.id}
              >
                <div className="absolute flex flex-col right-2 top-2 items-center justify-center gap-y-1">
                  <button
                    className=" text-lg lg:text-xl  "
                    onClick={() => handleShowModal(data?.id)}
                  >
                    <FaEdit />
                  </button>

                  <button
                    className="text-xl lg:text-2xl"
                    onClick={() => setDeleteCatalog(data?.id)}
                  >
                    <MdDelete />
                  </button>
                </div>
                {deleteCatalog !== null && (
                  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-15 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg">
                      <h2 className="text-lg font-semibold mb-4">
                        Are you sure you want to delete this catalogue?
                      </h2>
                      <div className="flex justify-between">
                        <button
                          className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                          onClick={() => handleDeleteConfirm(deleteCatalog)}
                        >
                          Delete
                        </button>
                        <button
                          className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                          onClick={() => setDeleteCatalog(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <img src={data?.image_url} alt="" className="h-52 w-full" />
                <h1 className="mt-1 font-semibold">
                  Title - {data.catalogue_title}
                </h1>
                <a
                  href={data?.pdf_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold text-blue-600 block"
                >
                  PDF Link
                </a>
                <button
                  className="border-2 border-black px-5 text-lg font-semibold mt-4"
                  onClick={() => downloadPdf(data.pdf_url)}
                >
                  Download
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showModal && (
        <CatalogueModal
          setShowModel={setShowModal}
          getAllCatalogueData={getAllCatalogueData}
          editId={editId}
        />
      )}
    </>
  );
};

export default Catalogue;
