import React, { useEffect, useRef } from 'react';

const Translate = () => {
    const googleTranslateRef = useRef("");

    useEffect(() => {
        const checkGoogleTranslate = () => {
            if (window.google && window.google.translate && window.google.translate.TranslateElement) {
                new window.google.translate.TranslateElement(
                    { pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE },
                    googleTranslateRef.current
                );
                return true;
            }
            return false;
        };

        const intervalId = setInterval(() => {
            if (checkGoogleTranslate()) {
                clearInterval(intervalId);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    return <div ref={googleTranslateRef} id="google_translate_element"></div>;
};

const App = () => (
    <div className="">
        <Translate />
    </div>
);

export default App;
