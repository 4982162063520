import React, { useEffect, useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { GrStatusGood } from "react-icons/gr";
import { FaEye } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { backend_url } from "../App";
import Cookies from "js-cookie";

const Admin1 = ({ id }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCheckingId, setIsCheckingId] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Check if adminId exists in localStorage
    const token = localStorage.getItem("adminId");
    if (token) {
      // If adminId exists, navigate to the tradeshow page
      navigate("/admin/tradeshow");
    } else {
      // Otherwise, stop checking and show the login form
      setIsCheckingId(false);
    }
  }, [navigate, localStorage.getItem("adminId")]);

  const fetchData = async () => {
    setLoading(true); // Set loading to true before the API call
    const options = {
      method: "POST",
      url: `${backend_url}/admin/admin-login`,
      params: { email, password },
    };

    try {
      const response = await axios.request(options);

      // Set admin ID in cookies
      localStorage.setItem("adminId", response.data.admin_id);
      // Cookies.set("adminId", response.data.admin_id, { expires: 7 });

      // Show success and navigate
      toast.success(response.data.message);
      setEmail("");
      setPassword("");
    } catch (error) {
      console.error(error);
      toast.error("Login failed! Please check your credentials.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const [backgroundImage, setBackgroundImage] = useState(
    "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg"
  );

  const [open, setopen] = useState(true);
  const changeBackgroundImage = () => {
    // Update the background image URL
    setBackgroundImage("");
  };

  if (isCheckingId || loading) {
    return (
      <div className="flex justify-center items-center py-20 h-screen">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className=" min-h-[100vh] m-auto bg-cover bg-center  inset-0"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="text-white flex justify-center items-center py-20 ">
        <div>
          <h1 className="text-4xl font-bold text-center mb-5">Admin</h1>
          <h2 className="mt-2">Username</h2>
          <div className="  w-[250px] h-[40px] sm:w-[302px] sm:h-[40px] bg-white text-black rounded-sm flex">
            <MdOutlineMail className=" text-lg sm:text-xl m-2 " />
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              className="w-full text-black outline-none"
            />
            <GrStatusGood className="text-lg sm:text-xl m-2 " />
          </div>
          <h2 className="mt-2">Password</h2>
          <div className=" w-[250px] h-[40px] sm:w-[302px] sm:h-[40px] bg-white text-black rounded-sm flex ">
            <CiLock className=" text-lg sm:text-xl m-2 text-zinc-800 font-bold" />
            <input
              type={open ? "password" : "text"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full text-black  outline-none"
            />
            <FaEye
              className=" text-lg sm:text-xl m-2"
              onClick={() => setopen(!open)}
            />
          </div>
          <br />
          <button
            onClick={fetchData}
            className="bg-[#e8d88f] text-black w-[250px] h-[40px] sm:w-[302px] sm:h-[40px] rounded-md mt-6"
            disabled={loading}
          >
            {loading ? "Loading..." : "Login"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin1;
