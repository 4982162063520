import React from "react";


const Sales = () => {
  return (
   <>
   
   </>
  );
};

export default Sales;
