import React, { useEffect } from "react";
import Caraousel from "../Components/Caraousel";
import ImageSlider from "../Components/Carousel-2";
import Products from "../Components/Products";
import Testimonials from "../Components/TestimonialCaraou";
import ImageCarousel from "../Components/ImageCarousel";

const LandingPage = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <div className="">
        <ImageCarousel />
      </div>
      <Products />

      <div className="w-full flex justify-center">
        <div className="w-full sm:w-[90%] overflow-hidden px-3 sm:px-5">
          <h2 className="font-semibold text-2xl sm:text-3xl text-center">
            UPCOMING TRADESHOWS
          </h2>
          <div className="">
            <Caraousel className="" />
          </div>
        </div>
      </div>
      <div
        className="
        mb-10
      "
      >
        <Testimonials />
      </div>
    </>
  );
};

export default LandingPage;
