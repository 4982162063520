/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const CatalogueModal = ({ setShowModel, editId, getAllCatalogueData }) => {
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");

  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setImageFile(selectedFile);
      setImage(URL.createObjectURL(selectedFile));
    }
  };

  const handlePdfChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setPdfFile(selectedFile);
    }
  };

  useEffect(() => {
    // console.log(pdfFile);
    if (editId) {
      // console.log(editId);
      getSingleCatalogueData();
    }
  }, [editId]);

  const createCatalogue = () => {
    setLoading(true);
    const fields = { title, description, pdfFile, imageFile };
    const emptyFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (emptyFields.length === 0) {
      const formData = new FormData();
      // console.log(title, description, file, pdfFile);
      // formData.append("catalogue_title", title);
      // formData.append("catalogue_description", description);
      formData.append("image", imageFile);
      formData.append("pdf", pdfFile);

      const options = {
        method: "POST",
        url: `${backend_url}/catalogue/create-catalogue`,
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        params: {
          catalogue_title: title,
          catalogue_description: description,
        },
        data: formData,
      };

      axios
        .request(options)
        .then((response) => {
          toast.success("Catalogue created successfully!");
          setShowModel(false);
          getAllCatalogueData();
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to create catalogue");
          setLoading(false);
        });
    } else {
      // Some fields are empty, display an error message
      const missingFields = emptyFields.join(", ");
      toast.error(`Please fill in the following fields: ${missingFields}`);
    }
  };
  const editCatalogue = () => {
    setLoading(true);
    const fields = { title, description };
    const emptyFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (emptyFields.length === 0) {
      const formData = new FormData();

      if (imageFile) {
        formData.append("image", imageFile);
      } else {
        formData.append("image", "");
      }

      if (pdfFile) {
        formData.append("pdf", pdfFile);
      } else {
        formData.append("pdf", "");
      }

      const options = {
        method: "PATCH", // Use PATCH method for updating
        url: `${backend_url}/catalogue/update-catalogue/${editId}`,
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        params: {
          catalogue_title: title,
          catalogue_description: description,
        },
        data: formData,
      };

      axios
        .request(options)
        .then((response) => {
          toast.success("Catalogue updated successfully!");
          setShowModel(false);
          getAllCatalogueData();
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to update catalogue");
          setLoading(false);
        });
    } else {
      // Some fields are empty, display an error message
      const missingFields = emptyFields.join(", ");
      toast.error(`Please fill in the following fields: ${missingFields}`);
    }
  };

  const getSingleCatalogueData = () => {
    // console.log(editId, "this si delete id");
    const options = {
      method: "GET",
      url: `${backend_url}/catalogue/get-catalogue?catalogue_id=${editId}`, // Adjust the URL as needed
    };

    axios
      .request(options)
      .then((response) => {
        const data = response.data;
        if (data) {
          setTitle(data.catalogue_title || ""); // Ensure that the title is set to an empty string if it's undefined
          setDescription(data.catalogue_description || ""); // Ensure that the description is set to an empty string if it's undefined
          setImage(data.image_url || "");
          setFileUrl(data.pdf_url); // Ensure that the image is set correctly
          // console.log(data, "single data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const close = () => {
    setShowModel(false);
  };

  return (
    <>
      <div className="fixed inset-0 flex justify-center z-50">
        <div className="absolute inset-0 bg-black/60 opacity-75 "></div>
        <div
          className={`bg-white rounded-md z-50 self-start min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-8 sm:mr-20 px-4 relative`}
        >
          <button
            className="absolute right-3 top-3 font-bold text-xl"
            onClick={close}
          >
            <RxCrossCircled />
          </button>

          <div className="w-full mt-5 flex justify-center items-center">
            <div className="w-[400px] flex flex-col gap-2">
              <div className="flex flex-col">
                <label htmlFor="title" className="font-bold">
                  Title:
                </label>
                <input
                  type="text"
                  value={title}
                  id="title"
                  className="border p-2 capitalize"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="description" className="font-bold">
                  Description:
                </label>
                <input
                  type="text"
                  value={description}
                  id="description"
                  className="border p-2 capitalize"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="flex flex-col">
                <h2 className="font-semibold">Add PDF:</h2>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handlePdfChange}
                />
                {fileUrl && (
                  <a
                    href={fileUrl}
                    target="blank"
                    className="bg-[#edebeb] rounded-sm border border-black p-1 w-28 mt-2"
                  >
                    Uploaded file
                  </a>
                )}
              </div>
              <div className="flex flex-col">
                <h2 className="font-semibold">Add Image:</h2>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                {imageFile || image ? (
                  <img
                    src={image ? image : URL.createObjectURL(imageFile)}
                    alt="Image"
                    style={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                      background: "cover",
                      marginTop: "5px",
                    }}
                  />
                ) : null}
              </div>
              <div className="flex justify-end">
                {loading ? (
                  <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                    Loading
                  </button>
                ) : editId.length > 0 ? (
                  <button
                    onClick={editCatalogue}
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  >
                    Edit Catalogue
                  </button>
                ) : (
                  <button
                    onClick={createCatalogue}
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  >
                    Add Catalogue
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogueModal;
