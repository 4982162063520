import React, { useState, useEffect } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const BusinessModal = ({
  handleModal,
  setShowModal,
  getBusinessData,
  id,
  setCurrentId,
}) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [detail, setDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  useEffect(() => {
    getBusiness();
  }, []);

  const getBusiness = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/business/get-business-form`,
      params: { business_id: id },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setName(response.data.contact_person);
        setEmail(response.data.email);
        setMobile(response.data.mobile);
        setCompany(response.data.company_name);
        setCity(response.data.city);
        setState(response.data.state);
        setCountry(response.data.country);
        setAddress(response.data.address);
        setDetail(response.data.details);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const close = () => {
    setShowModal(false);
    setCurrentId(null);
  };

  const validateMobile = (mobile) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const Edit = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!validateMobile(mobile)) {
      setMobileError("Please enter a valid mobile number.");
      return;
    }

    const options = {
      method: "PATCH",
      url: `${backend_url}/business/edit-business-form/${id}`,
      headers: { "Content-Type": "application/json" },
      data: {
        company_name: company,
        contact_person: name,
        email: email,
        mobile: mobile,
        city: city,
        state: state,
        country: country,
        address: address,
        details: detail,
      },
    };

    setLoading(true);

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        toast.success("Bussiness Query updated successfully!");
        setShowModal(false);
        getBusinessData();
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="fixed inset-0 flex  justify-center z-50">
        <div className="absolute inset-0 bg-black/60 opacity-75 "></div>
        <div
          className={`bg-white rounded-md z-50 self-start  min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-8  sm:mr-20 px-4 relative`}
        >
          <button className="absolute right-3 top-3   font-bold text-xl">
            <RxCrossCircled onClick={close} />
          </button>

          <div className="w-full mt-5  flex justify-center items-center ">
            <div className="w-[400px] flex  flex-col gap-2">
              <div className="flex gap-5">
                <div className="flex flex-col ">
                  <label htmlFor="person_name" className="font-bold">
                    Name:
                  </label>
                  <input
                    type="text"
                    value={name}
                    id=""
                    className="border p-2"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="content" className="font-bold">
                    Mobile:
                  </label>
                  <input
                    type="text"
                    value={mobile}
                    maxLength={10}
                    className="border p-2"
                    onChange={(e) => {
                      const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                      setMobile(onlyNum);
                      setMobileError("");
                    }}
                  />
                  {mobileError && <p className="text-red-500">{mobileError}</p>}
                </div>
              </div>
              <div className="flex gap-5">
                <div className="flex flex-col">
                  <label htmlFor="company" className="font-bold">
                    Company:
                  </label>
                  <input
                    type="text"
                    value={company}
                    className="border p-2"
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="content" className="font-bold">
                    Email:
                  </label>
                  <input
                    type="email"
                    value={email}
                    className="border p-2"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                  {emailError && <p className="text-red-500">{emailError}</p>}
                </div>
              </div>
              <div className="flex gap-5">
                <div className="flex flex-col">
                  <label htmlFor="rating" className="font-bold">
                    City:
                  </label>
                  <input
                    type="text"
                    value={city}
                    className="border p-2"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="rating" className="font-bold">
                    State:
                  </label>
                  <input
                    type="text"
                    value={state}
                    className="border p-2"
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-5">
                <div className="flex flex-col">
                  <label htmlFor="rating" className="font-bold">
                    Country:
                  </label>
                  <input
                    type="text"
                    value={country}
                    className="border p-2"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="rating" className="font-bold">
                    Address:
                  </label>
                  <input
                    type="text"
                    value={address}
                    className="border p-2"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label htmlFor="rating" className="font-bold">
                  Details:
                </label>
                <input
                  type="text"
                  value={detail}
                  className="border p-2"
                  onChange={(e) => setDetail(e.target.value)}
                />
              </div>
              <div className="flex justify-end ">
                {loading ? (
                  <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={Edit}
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessModal;
