import React, { useEffect, useState } from "react";
// import { RxCrossCircled } from "react-icons/rx";
import TradeshowModal from "../components/TradeshowModal";
import { TiEdit } from "react-icons/ti";
import { backend_url } from "../../App";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";

const Tradshow = () => {
  const [showModal, setShowModal] = useState(false);
  const [tradeshow, setTradeshow] = useState([]);
  const [showthreeDot, setShowthreeDot] = useState(false);
  const [id, setId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  useEffect(() => {
    getDataTrad();
  }, []);

  const getDataTrad = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/tradeshow/get-all-tradeshow`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setTradeshow(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const editHandel = (id) => {
    setId(id);
    setShowModal(true);
    setShowthreeDot();
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = (id) => {
    const options = {
      method: "DELETE",
      url: `${backend_url}/tradeshow/delete-tradeshow/${id}`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        handleDeleteCancel();
        toast.success(response.data.message);
        getDataTrad();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDeleteCancel = () => {
    setDeleteId(null);
  };

  return (
    <>
      <div className="pb-5 pt-3">
        <div className="xl:w-[1100px] m-auto px-4 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold ">Trade shows</h1>
            <button
              className="border-2 border-black px-5 text-lg  h-9 font-semibold"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {tradeshow.map((data, id) => (
              <div className=" w-full flex flex-col gap-1 " key={id}>
                <div className="w-full relative">
                  <a href={data.url_link} target="blank" className="">
                    <img
                      src={data.tradeshow_image}
                      alt=""
                      className="w-full h-[200px]"
                    />
                  </a>
                  {showthreeDot === data?.id && (
                    <div className="absolute right-7 flex flex-col gap-y-1 items-center top-2 text-black text-2xl bg-white  p-2 rounded-lg">
                      <button onClick={() => editHandel(data?.id)}>
                        <TiEdit />
                      </button>
                      <button onClick={() => handleDelete(data?.id)}>
                        <MdDeleteForever />
                      </button>
                    </div>
                  )}
                  <button
                    className="absolute right-2 top-2 text-gray-500 font-bold text-2xl"
                    onClick={() => {
                      showthreeDot
                        ? setShowthreeDot("")
                        : setShowthreeDot(data?.id);
                    }}
                  >
                    <BsThreeDotsVertical />
                  </button>
                </div>
                <h1 className=" text-lg font-semibold">
                  {data.tradeshow_name}
                </h1>
              </div>
            ))}
          </div>
        </div>
      </div>
      {deleteId !== null && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">
              Are you sure you want to delete this trade show?
            </h2>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                onClick={() => handleDeleteConfirm(deleteId)}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="">
          <TradeshowModal
            setShowModal={setShowModal}
            id={id}
            setId={setId}
            getDataTrad={getDataTrad}
          />
        </div>
      )}
    </>
  );
};

export default Tradshow;
