import React, { useEffect, useState } from "react";
import axios from "axios";
import { backend_url } from "../../App";
import ContactModal from "../components/ContactModal";
import { FaEdit } from "react-icons/fa";

const ContactUs = () => {
  const [contact, setContact] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contactId, setContactId] = useState(null);

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/contact/get-all-contact-form`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setContact(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const edit = (id) => {
    setShowModal(true);
    setContactId(id);

    // console.log(id, "EDIT KA ID");
  };
  const handleModal = () => {
    getContacts();
    setShowModal(false);
  };

  return (
    <>
      <div className="pb-5 pt-3">
        <div className="w-full xl:w-[1100px] m-auto px-4 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1
              className="text-2xl font-semibold cursor-pointer"
              onClick={getContacts}
            >
              Contacts
            </h1>
          </div>
          <div className="">
            <table className="table-auto border-collapse border border-gray-500 min-w-[800px]">
              <thead>
                <tr>
                  <th className="border-2 border-gray-500 px-4 py-2 w-[20%]">
                    Name
                  </th>
                  <th className="border-2 border-gray-500 px-4 py-2 w-[20%]">
                    Email
                  </th>

                  <th className="border-2 border-gray-500 px-4 py-2 w-[10%]">
                    Mobile
                  </th>
                  <th className="border-2 border-gray-500 px-4 py-2 w-[30%]">
                    Purpose
                  </th>
                  <th className="border-2 border-gray-500 px-4 py-2 w-[10%]">
                    Country
                  </th>
                  <th className="border-2 border-gray-500 px-4 py-2 w-[30%]">
                    Message
                  </th>
                </tr>
              </thead>
              <tbody>
                {contact.map((contact) => (
                  <tr key={contact.id}>
                    <td className="border border-gray-500 px-4 py-2">
                      {contact.full_name}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {contact.email}
                    </td>

                    <td className="border border-gray-500 px-4 py-2">
                      {contact.mobile}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {contact.purpose}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {contact.country}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {contact.message}
                    </td>
                    <td className="border border-gray-500 px-4 py-2 cursor-pointer">
                      <FaEdit onClick={() => edit(contact.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showModal && (
        <ContactModal
          handleModal={handleModal}
          setShowModal={setShowModal}
          id={contactId}
          setContactId={setContactId}
          getContacts={getContacts}
          contact={contact}
        />
      )}
    </>
  );
};

export default ContactUs;
