import React, { useEffect, useState } from "react";
import axios from "axios";

import { backend_url } from "../../App";
import { FaEdit } from "react-icons/fa";
import BussinessModal from "../components/BussinessModal";

const BusinessQuery = () => {
  const [queries, setQueries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    getBusinessData();
  }, []);

  const getBusinessData = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/business/get-all-business-form`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setQueries(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  // console.log(queries, "queries ka data");

  const edit = (id) => {
    setShowModal(true);
    setCurrentId(id);
    // console.log(id, "EDIT KA ID");
  };

  const handleModal = () => {
    getBusinessData();
    setShowModal(false);
  };

  return (
    <>
      <div className="pb-5 pt-3">
        <div className="w-full xl:w-[1100px] m-auto px-4 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold">Business Query</h1>
          </div>
          <div className="">
            <table className="table-auto border-collapse border border-gray-500 min-w-[900px] ">
              <thead>
                <tr>
                  <th className="border-2 border-gray-500 px-4 py-2">Name</th>
                  <th className="border-2 border-gray-500 px-4 py-2">Email</th>
                  <th className="border-2 border-gray-500 px-4 py-2">
                    Company
                  </th>
                  <th className="border-2 border-gray-500 px-4 py-2">Mobile</th>
                  <th className="border-2 border-gray-500 px-4 py-2">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {queries.map((query) => (
                  <tr key={query.id}>
                    <td className="border border-gray-500 px-4 py-2">
                      {query.contact_person}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {query.email}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {query.company_name}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {query.mobile}
                    </td>
                    <td className="border border-gray-500 px-4 py-2">
                      {query.details}
                    </td>
                    <td className="border border-gray-500 px-4 py-2 cursor-pointer">
                      <FaEdit onClick={() => edit(query.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showModal && (
        <BussinessModal
          handleModal={handleModal}
          setShowModal={setShowModal}
          id={currentId}
          setCurrentId={setCurrentId}
          getBusinessData={getBusinessData}
        />
      )}
    </>
  );
};

export default BusinessQuery;
