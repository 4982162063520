import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const PageNotFound = ({ setNotFound }) => {
  useLayoutEffect(() => {
    setNotFound(true);
  }, []);
  return (
    <div className="flex flex-col items-center sm:justify-center min-h-[100vh] py-20 sm:py-10 px-5 ">
      <div className="">
        <div className=" w-full md:w-[700px] lg:w-[500px] p-5 sm:p-10   shadow-xl  ">
          <div className="mb-5">
            <img
              src="/operose-removebg-preview.png"
              className="m-auto mb-2 h-24 w-24"
              alt="concur-logo"
            />
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-xl sm:text-3xl text-heading">Page not found</h1>
            <h1 className="mt-5 text-5xl mb-2">404</h1>
            <p>Could not find requested resource</p>
          </div>

          <div className="flex justify-center mt-6">
            <Link
              onClick={() => setNotFound(false)}
              to="/"
              className="border text-center p-2 w-full md:w-[400px] bg-blue-500 text-white  m-auto bg-primary "
            >
              Go to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
