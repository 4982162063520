import React from "react";

const Dashbord = () => {
  return (
    <>
      <div className="">
        <div className="max-w-[1100px] m-auto min-h-[90vh] flex items-center justify-center">
          <h1 className="text-xl font-bold">Dashboard </h1>
        </div>
      </div>
    </>
  );
};

export default Dashbord;
