import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backend_url } from "../../App";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { RxCrossCircled } from "react-icons/rx";

const Commodities = () => {
  const navigate = useNavigate();
  const [allCommodities, setAllCommodities] = useState([]);
  const [selectedCommodity, setSelectedCommodity] = useState(null);
  const [deleteCatalog, setDeleteCatalog] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [allSelectedCategory, setAllSelectedCategory] = useState([]);

  useEffect(() => {
    getCommodities();
  }, []);

  const addCommodity = () => {
    navigate(`/admin/create-commoditi/1`);
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/category/get-all-category`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const editCommodity = (id) => {
    navigate(`/admin/create-commoditi/${id}`);
  };

  const getCommodities = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/commodity/get-all-commodity`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setAllCommodities(response.data);
        if (response.data.length > 0) {
          setSelectedCommodity(response.data[0]); // Select the first commodity by default
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDeleteConfirm = () => {
    const url = `${backend_url}/commodity/delete-commodity/${deleteCatalog}`;

    axios
      .delete(url, {
        headers: {
          accept: "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data);
        toast.success("Catalogue deleted successfully!");
        //  if (onSuccess) onSuccess();
        getCommodities();
        setDeleteCatalog(null);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to delete catalogue");
      });
  };

  const deleteSingleImage = async () => {
    try {
      const options = {
        method: "DELETE",
        url: `${backend_url}/commodity/delete-image-commodity-gallery/${deleteCatalog}`,
        params: {
          image_url: imageUrl,
        },
        headers: {
          accept: "application/json",
        },
      };

      const response = await axios.request(options);
      // console.log(response.data);
      toast.success(response.data.message);
      setDeleteCatalog(null);
      setImageUrl("");
      getCommodities();
    } catch (error) {
      console.error("Failed to delete image", error);
      throw error;
    }
  };

  const handleCommodityClick = (commodity) => {
    setSelectedCommodity(commodity);
  };

  const filterCategory = () => {
    const filtered = allCommodities.filter(
      (data) => data.commodity_category === selectedCategory
    );
    setAllSelectedCategory(filtered);
  };

  useEffect(() => {
    filterCategory();
  }, [selectedCategory]);

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="w-1/5  flex items-center mt-10 flex-col">
            <div className="w-full flex flex-col items-center">
              <h1 className="font-semibold text-black pt-4 text-xl text-center">
                {" "}
                Select Category
              </h1>
              <select
                onChange={(e) => setSelectedCategory(e.target.value)}
                name=""
                id=""
                className=" border-2 border-black rounded-lg h-10 px-2 w-[80%] "
              >
                <option value="" className="">
                  Select Category
                </option>
                {categories.map((data) => (
                  <option value={data.category_name} className="">
                    {data.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col items-center">
              <h1 className="font-semibold text-black pt-4">COMMODITIES</h1>
              {allSelectedCategory.map((commodity) => (
                <div
                  key={commodity.id}
                  className={`bg-gray-200 hover:bg-gray-300 w-52 h-10 flex items-center justify-center flex-col mt-3 pt-5 mb-3 hover:border-green-500 border-r-4 ${
                    selectedCommodity === commodity
                      ? "border-green-500 border-r-2-4"
                      : "border-orange-500 border-r-4"
                  }`}
                  onClick={() => handleCommodityClick(commodity)}
                >
                  <button className="text-black font-medium">
                    {commodity.commodity_name}
                  </button>
                  <br />
                </div>
              ))}
            </div>
          </div>
          <div className=" w-4/5 pb-10 relative">
            {selectedCommodity && (
              <>
                <div className="flex  mt-4 flex-col">
                  <h1 className="font-medium text-green-600 text-2xl">
                    <span className="font-bold">Category : </span>
                    {selectedCommodity.commodity_category}
                  </h1>
                  <h1 className="font-medium text-green-600 text-2xl">
                    {" "}
                    <span className="font-bold">Name : </span>
                    {selectedCommodity.commodity_name}
                  </h1>
                </div>
                <div className="my-3">
                  <img
                    src={selectedCommodity.commodity_image}
                    alt=""
                    className="h-40 w-40"
                  />
                </div>
                <p className="">
                  {" "}
                  <span className="text-lg font-medium">
                    Description :{" "}
                  </span>{" "}
                  {selectedCommodity.description}
                </p>
                <h1 className="text-lg font-medium">
                  Specification :{" "}
                  <span className="font-normal ">
                    {selectedCommodity.specification.specification}
                  </span>
                </h1>

                <h1 className="text-lg font-medium">
                  Category :
                  <span className="font-normal ">
                    {selectedCommodity.commodity_category}
                  </span>
                </h1>
                <h1 className="text-lg font-medium">
                  Description :
                  <span className="font-normal ">
                    {selectedCommodity.description}
                  </span>
                </h1>
                <h1 className="text-lg font-medium">
                  Packaging Details :
                  <span className="font-normal ">
                    {selectedCommodity.packaging_details}
                  </span>
                </h1>
                <h1 className="text-lg font-medium">
                  Products Quality :
                  <span className="font-normal ">
                    {selectedCommodity.quality}
                  </span>
                </h1>
                <h1>
                  {" "}
                  <span className="font-semibold">Created On : </span>
                  {selectedCommodity.created_on}
                </h1>

                <div className="">
                  <h1 className="text-lg font-medium">
                    The countries which are importing :
                    {selectedCommodity.countries_importing.map(
                      (country, index) => (
                        <span
                          className="font-medium mx-1 border-2 border-black px-2 py-1   "
                          key={index}
                        >
                          {country.value}
                        </span>
                      )
                    )}
                  </h1>
                  <h1 className="text-lg font-medium mt-4">
                    The countries which are exporting :
                    {selectedCommodity.countries_exporting.map(
                      (country, index) => (
                        <span
                          className="font-medium mx-1 border-2 border-black px-2 py-1   "
                          key={index}
                        >
                          {country.value}
                        </span>
                      )
                    )}
                  </h1>
                </div>
                <div className="">
                  <h1 className="font-semibold">FAQ's : </h1>
                  {selectedCommodity.FAQ.map((data, index) => (
                    <div className="" key={index}>
                      <h1 className="text-lg font-medium mt-2">
                        <span className="font-semibold">
                          Question {index + 1} :{" "}
                        </span>
                        {data.question}
                      </h1>
                      <h1 className="text-lg font-medium ">
                        <span className="font-semibold">
                          Answer {index + 1} :{" "}
                        </span>
                        {data.answer}
                      </h1>
                    </div>
                  ))}
                </div>
                   {
                    selectedCommodity?.commodity_gallery &&
                   
                <div className="">
                  <h1 className="font-semibold text-xl">Commodity gallery :</h1>
                  <div className="flex gap-2 my-2">
                    {selectedCommodity.commodity_gallery.map((img, index) => (
                      <div className="relative ">
                        <img
                          src={img}
                          alt=""
                          className="h-40 w-40 "
                          key={index}
                        />
                        <button
                          className="absolute right-2 top-2 text-white font-bold text-2xl"
                          onClick={() => {
                            setImageUrl(img);
                            setDeleteCatalog(selectedCommodity.id);
                          }}
                        >
                          <RxCrossCircled color="black" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
}
                <div className="text-3xl flex items-center justify-center gap-x-3 absolute top-10 right-8 ">
                  <button
                    className=""
                    onClick={() => editCommodity(selectedCommodity.id)}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className=""
                    onClick={() => setDeleteCatalog(selectedCommodity?.id)}
                  >
                    <MdDelete />
                  </button>
                </div>
                {deleteCatalog !== null && (
                  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-15 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg">
                      <h2 className="text-lg font-semibold mb-4">
                        Are you sure you want to delete this{" "}
                        {deleteCatalog && imageUrl
                          ? "commodity image?"
                          : "commodity?"}
                      </h2>
                      <div className="flex justify-between">
                        <button
                          className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                          onClick={() => {
                            deleteCatalog && imageUrl
                              ? deleteSingleImage()
                              : handleDeleteConfirm();
                          }}
                        >
                          Delete
                        </button>
                        <button
                          className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                          onClick={() => setDeleteCatalog(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div>
            <button
              className="border-2 border-black px-5 text-md h-20 font-semibold"
              onClick={addCommodity}
            >
              Add Commodities
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commodities;
