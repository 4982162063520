import React, { useState, useEffect } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const ContactModal = ({
  handleModal,
  getContacts,
  setShowModal,
  setContactId,
  id,
  contact,
}) => {
  const [fullName, setFullName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState(null);
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  useEffect(() => {
    if (id) {
      filterUser();
    }
  }, [id]);

  const filterUser = () => {
    const filterData = contact.find((data) => data.id === id);
    setFullName(filterData.full_name);
    setPurpose(filterData.purpose);
    setEmail(filterData.email);
    setMobile(filterData.mobile);
    setCountry(filterData.country);
    setMessage(filterData.message);
  };

  const close = () => {
    setShowModal(false);
    setContactId(null);
  };
  const validateMobile = (mobile) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const editContact = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!validateMobile(mobile)) {
      setMobileError("Please enter a valid mobile number.");
      return;
    }

    const options = {
      method: "PATCH",
      url: `${backend_url}/contact/edit-contact-form/${id}`,
      headers: { "Content-Type": "application/json" },
      data: {
        full_name: fullName,
        purpose: purpose,
        message: message,
        email: email,
        mobile: mobile,
        country: country,
      },
    };

    setLoading(true);

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success("Updated contact Successfully");
        setShowModal(false);
        setLoading(false);
        getContacts();
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="fixed inset-0 flex  justify-center z-50">
        <div className="absolute inset-0 bg-black/60 opacity-75 "></div>
        <div
          className={`bg-white rounded-md z-50 self-start  min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-4  sm:mr-20 px-4 relative`}
        >
          <button className="absolute right-3 top-3   font-bold text-xl">
            <RxCrossCircled onClick={close} />
          </button>

          <div className="w-full mt-5  flex justify-center items-center ">
            <div className="w-[400px] flex  flex-col gap-2">
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Name:
                </label>
                <input
                  type="text"
                  value={fullName}
                  id=""
                  className="border p-2"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <label for="designation" className="font-bold">
                  Purpose:
                </label>
                <input
                  type="text"
                  value={purpose}
                  className="border p-2"
                  onChange={(e) => setPurpose(e.target.value)}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="content" className="font-bold">
                  Email:
                </label>
                <input
                  type="email"
                  value={email}
                  className="border p-2"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <p className="text-red-500">{emailError}</p>}
              </div>
              <div className="flex flex-col">
                <label htmlFor="content" className="font-bold">
                  Mobile:
                </label>
                <input
                  type="text"
                  value={mobile}
                  maxLength={10}
                  className="border p-2"
                  onChange={(e) => {
                    const onlyNum = e.target.value.replace(/[^0-9]/g, "");
                    setMobile(onlyNum);
                    setMobileError("");
                  }}

                  // onChange={(e) => {
                  //   setMobile(e.target.value);
                  //   setMobileError("");
                  // }}
                />
                {mobileError && <p className="text-red-500">{mobileError}</p>}
              </div>

              <div className="flex flex-col">
                <label for="rating" className="font-bold">
                  Country:
                </label>
                <input
                  type="text"
                  value={country}
                  className="border p-2"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <label for="rating" className="font-bold">
                  Message:
                </label>
                <textarea
                  type="text"
                  value={message}
                  className="border p-2"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mt-2">
            {loading ? (
              <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                Loading...
              </button>
            ) : (
              <button
                onClick={editContact}
                className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
              >
                Save Changes
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactModal;
