import React, { useEffect, useState } from "react";
import { CgWebsite } from "react-icons/cg";
import { FaEdit, FaFilePdf } from "react-icons/fa";
import { IoVideocam } from "react-icons/io5";
import { backend_url } from "../../App";
// import UpdatesModal from "../components/Modal";
import axios from "axios";
import dayjs from "dayjs";
import UpdatesModal from "../components/UpdatesModal";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";

const Updates = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [updateId, setUpdateId] = useState();
  useEffect(() => {
    getUpdates();
  }, []);

  const getUpdates = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/update/get-all-updates`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setUpdates(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleModal = (id) => {
    // console.log(id, "id");
    setUpdateId(id);
    setShowModal(true);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  const handleDeleteConfirm = (id) => {
    const options = {
      method: "DELETE",
      url: `${backend_url}/update/delete-update/${id}`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message);
        getUpdates();
        handleDeleteCancel();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleDeleteCancel = () => {
    setDeleteId(null);
  };

  // const downloadPdf = (url) => {
  //   // Path to your PDF file
  //   const pdfPath = { url };
  //   console.log(url)
  //   // Create a link element
  //   const link = document.createElement("a");

  //   // Set its href attribute to the path of the PDF
  //   link.href = pdfPath;

  //   // Set the download attribute to force download the file
  //   link.setAttribute("download", "example.pdf");

  //   // Append the link to the body
  //   document.body.appendChild(link);

  //   // Trigger a click event on the link
  //   link.click();

  //   // Clean up: remove the link from the DOM
  //   document.body.removeChild(link);
  // };

  return (
    <>
      <div className="pt-3 pb-5">
        <div className="w-full xl:w-[1100px] m-auto px-4 sm:px-7 xl:px-0">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold ">Updates</h1>
            <button
              className="border-2 border-black px-5 text-lg  h-9 font-semibold"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-1 gap-5">
            {updates.map((data) => (
              <div
                className="border-2 border-black w-full flex flex-col lg:flex-row gap-4 items-center lg:h-[200px] p-3 "
                key={data.id}
              >
                <div className="">
                  <img
                    src={data.image_url}
                    alt=""
                    className="h-[180px] w-[195px]"
                  />
                </div>
                <div className="w-full">
                  <h1 className="capitalize">
                    <span className="font-bold">Title - </span> {data.title}
                  </h1>
                  <h1 className="capitalize">
                    {" "}
                    <span className="font-bold">Content -</span> {data.content}
                  </h1>
                  <h1 className="capitalize">
                    {" "}
                    <span className="font-bold">Category -</span>
                    {data.category}
                  </h1>
                  <h1 className="capitalize">
                    {" "}
                    <span className="font-bold">Author -</span> {data.author}
                  </h1>
                  <h1 className="capitalize">
                    {" "}
                    <span className="font-bold">Date -</span>{" "}
                    {dayjs(data.date).format("DD/MM/YYYY")}
                  </h1>
                  <div className="flex w-full justify-between">
                    <div className="font-semibold flex gap-6 items-center mt-3">
                      {data.website_link && (
                        <a
                          href={data.website_link}
                          target="blank"
                          className="text-3xl"
                        >
                          <CgWebsite />
                        </a>
                      )}
                      {data.video_link && (
                        <a
                          href={data.video_link}
                          target="blank"
                          className="text-3xl"
                        >
                          <IoVideocam />
                        </a>
                      )}
                      {data.pdf_doc_url && (
                        <a
                          className="text-2xl"
                          target="blank"
                          href={data.pdf_doc_url}
                        >
                          <FaFilePdf />
                        </a>
                      )}
                    </div>
                    <div className="font-semibold flex gap-6 items-center mt-3">
                      <button
                        onClick={() => handleModal(data.id)}
                        target="blank"
                        className="text-2xl"
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="text-2xl"
                        target="blank"
                        onClick={() => handleDelete(data.id)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {deleteId !== null && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-lg font-semibold mb-4">
              Are you sure you want to delete this Updates?
            </h2>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600"
                onClick={() => handleDeleteConfirm(deleteId)}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg ml-2 hover:bg-gray-600"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div className="">
          <UpdatesModal
            setShowModal={setShowModal}
            getUpdates={getUpdates}
            id={updateId}
            setId={setUpdateId}
          />
        </div>
      )}
    </>
  );
};

export default Updates;
