/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { backend_url } from "../../App";
import toast from "react-hot-toast";

const UpdatesModal = ({ setShowModal, id, setId, getUpdates }) => {
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState("");
  const [pdfImage, setPdfImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState();
  const [content, setContent] = useState();
  const [author, setAuthor] = useState();
  const [websiteLink, setWebsiteLink] = useState("");
  const [videoLink, setVideoLink] = useState("");

  // console.log(id, "hello im id");

  const handleChangeImage = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImage(URL.createObjectURL(selectedFile));
    }
  };
  const handleChangePdf = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setPdf(selectedFile);
      setPdfImage(URL.createObjectURL(selectedFile));
    }
  };

  useEffect(() => {
    if (id) {
      const options = {
        method: "GET",
        url: `${backend_url}/update/get-update`,
        params: { update_id: id },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          setTitle(response.data.title);
          setCategory(response.data.category);
          setContent(response.data.content);
          setAuthor(response.data.author);
          setWebsiteLink(response.data.website_link);
          setVideoLink(response.data.video_link);
          setImage(response.data.image_url);
          setPdfImage(response.data.pdf_doc_url);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, []);

  const handleCreate = () => {
    const fields = {
      file,
      pdf,
      title,
      category,
      content,
      author,
      // websiteLink,
      // videoLink,
    };
    const emptyFields = Object.entries(fields)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (emptyFields.length === 0) {
      setLoading(true);
      const form = new FormData();
      form.append("image", file);
      form.append("pdf", pdf);
      const options = {
        method: "POST",
        url: `${backend_url}/update/create-update`,
        params: {
          title: title,
          category: category,
          content: content,
          author: author,
          website_link: websiteLink,
          video_link: videoLink,
        },
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: form,
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          toast.success(response.data.message);
          setShowModal(false);
          setLoading(false);
          getUpdates();
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    } else {
      // Some fields are empty, display an error message
      const missingFields = emptyFields.join(", ");
      toast.error(`Please fill in the following fields: ${missingFields}`);
    }
  };
  const close = () => {
    setId(null);
    setShowModal(false);
  };

  const Edit = () => {
    setLoading(true);
    const form = new FormData();
    form.append("image", file);
    form.append("pdf", pdf);
    const options = {
      method: "PATCH",
      url: `${backend_url}/update/edit-update/${id}`,
      params: {
        title: title,
        category: category,
        content: content,
        author: author,
        website_link: websiteLink,
        video_link: videoLink,
      },
      headers: { "Content-Type": "multipart/form-data" }, // Optional: axios will set this automatically
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast.success(response.data.message);
        setLoading(false);
        getUpdates();
        setShowModal(false);
        setId(null);
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="fixed inset-0 flex  justify-center z-50">
        <div className="absolute inset-0 bg-black/60 opacity-75 "></div>
        <div
          className={`bg-white rounded-md z-50 self-start  min-h-[50vh] py-5 h-auto w-full sm:w-4/5 md:w-3/5 xl:w-2/5 mt-8  sm:mr-20 px-4 relative`}
        >
          <button className="absolute right-3 top-3   font-bold text-xl">
            <RxCrossCircled onClick={close} />
          </button>

          <div className="w-full mt-5  flex justify-center items-center">
            <div className="w-[400px] flex  flex-col gap-2">
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Updates Title:
                </label>
                <input
                  type="text"
                  value={title}
                  id=""
                  className="border p-2 capitalize"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Category:
                </label>
                <input
                  type="text"
                  value={category}
                  id=""
                  className="border p-2 capitalize"
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Content:
                </label>
                <textarea
                  type="text"
                  value={content}
                  id=""
                  className="border p-2 capitalize"
                  onChange={(e) => setContent(e.target.value)}
                />
              </div>
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Author:
                </label>
                <input
                  type="text"
                  value={author}
                  id=""
                  className="border p-2 capitalize"
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Website Link:
                </label>
                <input
                  type="text"
                  value={websiteLink}
                  id=""
                  className="border p-2 capitalize"
                  onChange={(e) => setWebsiteLink(e.target.value)}
                />
              </div>
              <div className="flex flex-col ">
                <label for="person_name" className="font-bold">
                  Video Link:
                </label>
                <input
                  type="text"
                  value={videoLink}
                  id=""
                  className="border p-2 capitalize"
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <h2 className="font-semibold">Add Pdf:</h2>
                <input
                  type="file"
                  accept="application/pdf"
                  id="pdf"
                  onChange={handleChangePdf}
                />
                <a href={pdfImage} target="blank">
                  Chick pdf
                </a>
              </div>
              <div className="flex flex-col">
                <h2 className="font-semibold">Add Image:</h2>
                <input
                  type="file"
                  accept="application/image"
                  onChange={handleChangeImage}
                />
                {file || image ? (
                  <img
                    src={image ? image : URL.createObjectURL(file)}
                    alt="Image"
                    style={{
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                      background: "cover",
                      marginTop: "5px",
                    }}
                  />
                ) : null}
              </div>
              <div className="flex justify-end ">
                {id ? (
                  loading ? (
                    <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                      Loading...
                    </button>
                  ) : (
                    <button
                      onClick={Edit}
                      className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                    >
                      Save Changes
                    </button>
                  )
                ) : loading ? (
                  <button className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white">
                    loading
                  </button>
                ) : (
                  <button
                    onClick={handleCreate}
                    className="py-3 px-10 bg-orange-200 hover:bg-orange-400 rounded-md border font-medium hover:text-white"
                  >
                    Add Updates
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatesModal;
