import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { backend_url } from "../App";

const Carousel = () => {
  const [tradeShow, setTradeShow] = useState([]);
  const [screenWidth, setScreenWidth] = useState();

  useEffect(() => {
    getTradeshow();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 800) {
      setScreenWidth(2);
    } else {
      setScreenWidth(1);
    }
  }, [window.innerWidth]);

  const getTradeshow = () => {
    axios
      .get(`${backend_url}/tradeshow/get-all-tradeshow`)
      .then((response) => {
        setTradeShow(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let sliderRef = useRef(null);

  const PrevArrow = (props) => {
    const { className, onClick, style } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block", // Adjust left position
          top: "40%",
          zIndex: 1,
          transform: "translateY(-50%)", // Center vertically
        }}
        onClick={onClick}
      >
        <IoIosArrowBack className="text-4xl text-black hidden sm:block" />
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, onClick, style } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          top: "40%", // Adjust right position
          zIndex: 1,
          transform: "translateY(-50%)", // Center vertically
        }}
        onClick={onClick}
      >
        <IoIosArrowForward className="text-4xl text-black hidden sm:block " />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: screenWidth,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
   
  };

  return (
    <div className="w-full flex justify-center items-center p-10">
      <div className="w-full">
        <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
          {tradeShow.map((item) => (
            <div key={item.id} className="flex justify-center items-center">
              <div
                className="flex justify-around rounded-3xl border border-black border-dashed m-auto  w-[95%] sm:w-[90%] h-[150px] sm:h-[180px]"
                style={{
                  backgroundImage: `url(${item.tradeshow_image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // width: "90%",
                  // height: "250px",
                  objectFit: "cover",
                  // marginLeft: "35px",
                }}
              >
                <a
                  href={item.url_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={item.tradeshow_image}
                    style={{ visibility: "hidden", objectFit: "cover" }}
                    alt={item.tradeshow_name}
                    height={150}
                    width={150}
                  />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
