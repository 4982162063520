// // import React, { useLayoutEffect, useState } from "react";
// // import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
// // import "./App.css";
// // import About from "./Pages/About";
// // import Navbar from "./Components/Navbar";
// // import Footer from "./Components/Footer";
// // import LandingPage from "./Pages/LandingPage";
// // import BusinessQuery from "./Pages/BusinessQuery";
// // import Gallery1 from "./Pages/Gallery";
// // import Categories from "./Pages/Catagories";
// // import Contact from "./Pages/Contact";
// // import People from "./Pages/People";
// // import Products from "./Pages/Products";
// // import Admin1 from "./Pages/Admin";
// // import Sales from "./Pages/Sales";
// // import Why from "./Pages/Why";
// // import Faq from "./Pages/Faq";
// // import Navbar2 from "./admin/components/Navbar";
// // import Updates from "./admin/pages/Updates";
// // import Tradshow from "./admin/pages/Tradeshow";
// // import Gallery from "./admin/pages/Gallery";
// // import Catalogue from "./admin/pages/Catalogue";
// // import Testimonial from "./admin/pages/Testimonial";
// // import Category from "./admin/pages/Category";
// // import Commodities from "./admin/pages/Commodities";
// // import ContactUs from "./admin/pages/ContactUs";
// // import BusinessQueryAdmin from "./admin/pages/BusinessQuery";
// // import Dashbord from "./admin/pages/Dashboard";
// // import Product from "./Pages/Product";
// // import { Toaster } from "react-hot-toast";
// // import { CreateCommoditi } from "./admin/pages/CreateCommoditi";
// // import Update from "./Components/Update";
// // import Catalogues from "./Pages/Catalogues";
// // import PageNotFound from "./Pages/PageNotFound";

// // // export const backend_url = "https://rose-backend.onrender.com";
// // export const backend_url = "https://operose.abhishek-khare.me";

// // function App() {
// //   return (
// //     <Router>
// //       <AppContent />
// //     </Router>
// //   );
// // }

// // function AppContent() {
// //   const location = useLocation();
// //   const { pathname } = location;
// //   const isAdminPath = pathname.startsWith("/admin");
// //   const [id, setId] = useState(null);

// //   const [isLoading, setIsLoading] = useState(true);

// //   useLayoutEffect(() => {
// //     const token = localStorage.getItem("adminId");
// //     console.log(token, "for token");

// //     if (token) {
// //       setIsLoading(true)
// //       setId(token);
// //     }
// //     setIsLoading(false);
// //   }, [id]);

// //   if (isLoading) {
// //     return <div>Loading...</div>;
// //   }

// //   return (
// //     <>
// //       {isAdminPath ? <Navbar2 /> : <Navbar />}
// //       <div className="h-full w-full">
// //         <Routes>
// //           <Route path="/" element={<LandingPage />} />
// //           <Route path="/about" element={<About />} />
// //           <Route path="/business-query" element={<BusinessQuery />} />
// //           <Route path="/categories" element={<Categories />} />
// //           <Route path="/contact" element={<Contact />} />
// //           <Route path="/login" element={<Admin1 />} />
// //           <Route path="/product/:name" element={<Product />} />
// //           <Route path="/products" element={<Products />} />
// //           <Route path="/people" element={<People />} />
// //           <Route path="/Gallery" element={<Gallery1 />} />
// //           <Route path="/sales" element={<Sales />} />
// //           <Route path="/why" element={<Why />} />
// //           <Route path="/faq" element={<Faq />} />
// //           <Route path="/updatess" element={<Update />} />
// //           <Route path="/catalogues" element={<Catalogues />} />
// //           <Route path="*" element={<PageNotFound />} />
// //           <Route
// //             path="/admin/*"
// //             element={
// //               id ? (
// //                 <Routes>
// //                   <Route path="/" element={<Dashbord />} />
// //                   <Route path="/updates" element={<Updates />} />
// //                   <Route path="/tradeshow" element={<Tradshow />} />
// //                   <Route path="/gallery" element={<Gallery />} />
// //                   <Route path="/catalogue" element={<Catalogue />} />
// //                   <Route path="/testimonial" element={<Testimonial />} />
// //                   <Route path="/category" element={<Category />} />
// //                   <Route path="/commodities" element={<Commodities />} />
// //                   <Route path="/contact-us" element={<ContactUs />} />
// //                   <Route
// //                     path="/create-commoditi/:id"
// //                     element={<CreateCommoditi />}
// //                   />
// //                   <Route
// //                     path="/business-query"
// //                     element={<BusinessQueryAdmin />}
// //                   />
// //                   <Route path="*" element={<PageNotFound />} />
// //                 </Routes>
// //               ) : (
// //                 <Navigate to="/login" />
// //               )
// //             }
// //           />
// //         </Routes>

// //         <Toaster position="top-right" reverseOrder={false} />
// //       </div>
// //       {!isAdminPath && <Footer />}
// //     </>
// //   );
// // }

// // export default App;

import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import About from "./Pages/About";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import LandingPage from "./Pages/LandingPage";
import BusinessQuery from "./Pages/BusinessQuery";
import Gallery1 from "./Pages/Gallery";
import Categories from "./Pages/Catagories";
import Contact from "./Pages/Contact";
import People from "./Pages/People";
import Products from "./Pages/Products";
import Admin1 from "./Pages/Admin";
import Sales from "./Pages/Sales";
import Why from "./Pages/Why";
import Faq from "./Pages/Faq";
import Navbar2 from "./admin/components/Navbar";
import Updates from "./admin/pages/Updates";
import Tradshow from "./admin/pages/Tradeshow";
import Gallery from "./admin/pages/Gallery";
import Catalogue from "./admin/pages/Catalogue";
import Testimonial from "./admin/pages/Testimonial";
import Category from "./admin/pages/Category";
import Commodities from "./admin/pages/Commodities";
import ContactUs from "./admin/pages/ContactUs";
import BusinessQueryAdmin from "./admin/pages/BusinessQuery";
import Dashbord from "./admin/pages/Dashboard";
import Product from "./Pages/Product";
import { Toaster } from "react-hot-toast";
import { CreateCommoditi } from "./admin/pages/CreateCommoditi";
import Update from "./Components/Update";
import Catalogues from "./Pages/Catalogues";
import PageNotFound from "./Pages/PageNotFound";
import Cookies from "js-cookie";

// export const backend_url = "https://rose-backend.onrender.com";
export const backend_url = "https://api.operoseglobal.com";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const isAdminPath = pathname.startsWith("/admin");

  const [id, setId] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // useLayoutEffect to fetch cookie when app renders
  useEffect(() => {
    const token = localStorage.getItem("adminId");
    console.log(token, "for token");

    if (token) {
      setId(token);
      console.log(id, "id"); // Asynchronously sets the state
    }

    setIsLoading(false); // After checking token, stop loading
  }, [localStorage.getItem("adminId")]); // Empty dependency array to only run this on mount

  if (isLoading) {
    return <div>Loading...</div>; // Show loading while checking token
  }

  return (
    <>
      {isAdminPath ? notFound ? "" : <Navbar2 /> : notFound ? "" : <Navbar />}
      {/* Conditionally render Navbar */}
      <div className="h-full w-full">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/business-query" element={<BusinessQuery />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Admin1 id={id} />} />
          <Route path="/product/:name" element={<Product />} />
          <Route path="/products" element={<Products />} />
          <Route path="/people" element={<People />} />
          <Route path="/Gallery" element={<Gallery1 />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/why" element={<Why />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/updatess" element={<Update />} />
          <Route path="/catalogues" element={<Catalogues />} />
          <Route
            path="*"
            element={<PageNotFound setNotFound={setNotFound} />}
          />
          <Route
            path="/admin/*"
            element={
              id ? (
                <Routes>
                  <Route path="/" element={<Dashbord />} />
                  <Route path="/updates" element={<Updates />} />
                  <Route path="/tradeshow" element={<Tradshow />} />
                  <Route path="/gallery" element={<Gallery />} />
                  <Route path="/catalogue" element={<Catalogue />} />
                  <Route path="/testimonial" element={<Testimonial />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/commodities" element={<Commodities />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route
                    path="/create-commoditi/:id"
                    element={<CreateCommoditi />}
                  />
                  <Route
                    path="/business-query"
                    element={<BusinessQueryAdmin />}
                  />
                  <Route
                    path="*"
                    element={<PageNotFound setNotFound={setNotFound} />}
                  />
                </Routes>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
      {!isAdminPath && !notFound && <Footer />}
      {/* Conditionally render Footer */}
    </>
  );
}

export default App;

// import React, { useLayoutEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
//   useLocation,
// } from "react-router-dom";
// import "./App.css";
// import About from "./Pages/About";
// import Navbar from "./Components/Navbar";
// import Footer from "./Components/Footer";
// import LandingPage from "./Pages/LandingPage";
// import BusinessQuery from "./Pages/BusinessQuery";
// import Gallery1 from "./Pages/Gallery";
// import Categories from "./Pages/Catagories";
// import Contact from "./Pages/Contact";
// import People from "./Pages/People";
// import Products from "./Pages/Products";
// import Admin1 from "./Pages/Admin";
// import Sales from "./Pages/Sales";
// import Why from "./Pages/Why";
// import Faq from "./Pages/Faq";
// import Navbar2 from "./admin/components/Navbar";
// import Updates from "./admin/pages/Updates";
// import Tradshow from "./admin/pages/Tradeshow";
// import Gallery from "./admin/pages/Gallery";
// import Catalogue from "./admin/pages/Catalogue";
// import Testimonial from "./admin/pages/Testimonial";
// import Category from "./admin/pages/Category";
// import Commodities from "./admin/pages/Commodities";
// import ContactUs from "./admin/pages/ContactUs";
// import BusinessQueryAdmin from "./admin/pages/BusinessQuery";
// import Dashbord from "./admin/pages/Dashboard";
// import Product from "./Pages/Product";
// import { Toaster } from "react-hot-toast";
// import { CreateCommoditi } from "./admin/pages/CreateCommoditi";
// import Update from "./Components/Update";
// import Catalogues from "./Pages/Catalogues";
// import PageNotFound from "./Pages/PageNotFound";

// export const backend_url = "https://api.operoseglobal.com";

// function App() {
//   return (
//     <Router>
//       <AppContent />
//     </Router>
//   );
// }

// function AppContent() {
//   const [notFound, setNotFound] = useState(false);
//   const location = useLocation();
//   const { pathname } = location;
//   const isAdminPath = pathname.startsWith("/admin");

//   const [id, setId] = useState(localStorage.getItem("adminId")); // Simplified initial state for adminId
//   const [isLoading, setIsLoading] = useState(true);

//   useLayoutEffect(() => {
//     const token = localStorage.getItem("adminId");

//     if (token && !id) {
//       setId(token);
//     }
//     setIsLoading(false);
//   }, [id]);

//   // Loading state, could be a spinner or any loader component
//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       {!notFound && (isAdminPath ? <Navbar2 /> : <Navbar />)}
//       {/* Conditionally render Navbar */}

//       <div className="h-full w-full">
//         <Routes>
//           <Route path="/" element={<LandingPage />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/business-query" element={<BusinessQuery />} />
//           <Route path="/categories" element={<Categories />} />
//           <Route path="/contact" element={<Contact />} />
//           <Route path="/login" element={<Admin1 />} />
//           <Route path="/product/:name" element={<Product />} />
//           <Route path="/products" element={<Products />} />
//           <Route path="/people" element={<People />} />
//           <Route path="/Gallery" element={<Gallery1 />} />
//           <Route path="/sales" element={<Sales />} />
//           <Route path="/why" element={<Why />} />
//           <Route path="/faq" element={<Faq />} />
//           <Route path="/updatess" element={<Update />} />
//           <Route path="/catalogues" element={<Catalogues />} />
//           <Route
//             path="*"
//             element={<PageNotFound setNotFound={setNotFound} />}
//           />
//           <Route
//             path="/admin/*"
//             element={
//               id ? (
//                 <Routes>
//                   <Route path="/" element={<Dashbord />} />
//                   <Route path="/updates" element={<Updates />} />
//                   <Route path="/tradeshow" element={<Tradshow />} />
//                   <Route path="/gallery" element={<Gallery />} />
//                   <Route path="/catalogue" element={<Catalogue />} />
//                   <Route path="/testimonial" element={<Testimonial />} />
//                   <Route path="/category" element={<Category />} />
//                   <Route path="/commodities" element={<Commodities />} />
//                   <Route path="/contact-us" element={<ContactUs />} />
//                   <Route
//                     path="/create-commoditi/:id"
//                     element={<CreateCommoditi />}
//                   />
//                   <Route
//                     path="/business-query"
//                     element={<BusinessQueryAdmin />}
//                   />
//                   <Route
//                     path="*"
//                     element={<PageNotFound setNotFound={setNotFound} />}
//                   />
//                 </Routes>
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />
//         </Routes>

//         <Toaster position="top-right" reverseOrder={false} />
//       </div>

//       {!isAdminPath && !notFound && <Footer />}
//       {/* Conditionally render Footer */}
//     </>
//   );
// }

// export default App;
