import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './custom-carousel.css';  // Import the custom CSS file

const ImageCarousel = () => {
    const images = ['/image-1.png', '/image-2.png', '/image-3.png', '/image-4.png', '/image-5.png'];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                    <img
                        className="m-auto z-0 bg-no-repeat bg-cover h-[200px] w-full sm:h-[300px] md:h-[400px] lg:h-[600px]"
                        src={image}
                        alt={`Slide ${index + 1}`}
                    />
                </div>
            ))}
        </Slider>
    );
};

export default ImageCarousel;
